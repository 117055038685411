@import '../../mixins';

.root {
  @include flex($direction: column, $justify: center, $align: center);
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--exo-2-24-size);
  color: var(--black-000000);
}
.title {
  font-weight: 600;
}
.subtitle {
  font-size: var(--exo-2-16-size);
  color: var(--gray-81838e);
}
