@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  line-height: normal;
  font-family: var(--exo-2-24-regular);
  overflow-y: scroll !important;

  &.hidden {
    overflow: hidden !important;
  }
}
body * {
  box-sizing: border-box;
}
.MuiInputBase-root.Mui-error {
  border: 1px solid var(--red-ff3d00);
}
.MuiDayPicker-slideTransition {
  min-height: unset !important;
}
.MuiPickersCalendarHeader-root {
  display: none !important;
}

:root {
  /* fonts */
  --exo-2-24-regular: 'Exo 2';

  /* font sizes */
  --exo-2-10-size: 10px;
  --exo-2-12-size: 12px;
  --exo-2-14-size: 14px;
  --exo-2-16-size: 16px;
  --exo-2-18-size: 18px;
  --exo-2-20-size: 20px;
  --exo-2-24-size: 24px;
  --exo-2-28-size: 28px;
  --exo-2-29-size: 29px;
  --exo-2-32-size: 32px;
  --exo-2-38-size: 38px;
  --exo-2-40-size: 40px;
  --exo-2-48-size: 48px;
  --exo-2-96-size: 96px;

  /* Colors */
    --purple-6100FF: #6100FF;
    --purple-4701b2: #4701b2;



  --blue-007aff: #007aff;
  --blue-0068d9: #0068d9;
  --blue-bfdeff: #bfdeff;
  --blue-e5f2ff: #e5f2ff;

  --gray-33343a: #33343a;
  --gray-52545c: #52545c;
  --gray-81838e: #81838e;
  --gray-a5a7b2: #a5a7b2;
  --gray-d5d7e2: #d5d7e2;
  --gray-eef0f6: #eef0f6;
  --gray-f3f4fb: #f3f4fb;

  --red-ff3d00: #ff3d00;
  --orange-ffa620: #ffa620;
  --orange-ff7a00: #ff7a00;
  --green-60b56e: #60b56e;

  --color-gray-300: #e6e8f1;
  --color-gray-1100: rgba(255, 255, 255, 0.4);
  --color-gray-1200: rgba(255, 255, 255, 0.2);
  --color-gray-1300: rgba(255, 255, 255, 0.1);

  --white-ffffff: #fff;
  --black-222222: #222;
  --black-000000: #000;

  /* Gaps */
  --gap-6xs: 2px;
  --gap-5xs: 4px;
  --gap-4xs: 7px;
  --gap-3xs: 8px;
  --gap-2xs: 10px;
  --gap-xs: 12px;
  --gap-sm: 13px;
  --gap-md: 16px;
  --gap-lg: 20px;
  --gap-xl: 24px;
  --gap-2xl: 32px;
  --gap-3xl: 40px;
  --gap-4xl: 48px;
  --gap-5xl: 119px;
  --gap-6xl: 220px;

  /* Paddings */
  --max-width: 1440px;

  --padding-9xs: 4px;
  --padding-8xs: 6px;
  --padding-7xs: 8px;
  --padding-6xs: 10px;
  --padding-5xs: 11px;
  --padding-4xs: 12px;
  --padding-3xs: 16px;
  --padding-2xs: 18px;
  --padding-xs: 20px;
  --padding-sm: 24px;
  --padding-md: 32px;
  --padding-lg: 40px;
  --padding-xl: 48px;
  --padding-2xl: 56px;
  --padding-3xl: 58px;
  --padding-4xl: 64px;
  --padding-5xl: 72px;
  --padding-6xl: 96px;
  --padding-7xl: 128px;
  --padding-8xl: 144px;

  /* border radiuses */
  --br-3xs: 1px;
  --br-2xs: 2px;
  --br-xs: 4px;
  --br-sm: 8px;
  --br-md: 9px;
  --br-lg: 12px;
  --br-xl: 16px;
  --br-2xl: 18px;
  --br-3xl: 24px;

  /* Effects */
  --card: 0px 2px 12px rgba(68, 48, 95, 0.05);
  --header: 0px 2px 12px rgba(68, 48, 95, 0.1);
  --sh-tag-map: 0px 3px 8px rgba(0, 0, 0, 0.3);
  --search-field3: 0px 4px 12px rgba(0, 0, 0, 0.1);
  --window3: 0px 4px 12px rgba(0, 0, 0, 0.16);
  --window2: 0px 4px 16px rgba(33, 44, 85, 0.3);

  --box-shadow-main: 0px 4px 12px rgba(0, 0, 0, 0.16);
  --box-shadow-tooltip: 0px 3px 8px rgba(0, 0, 0, 0.3);
  --search-field: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
