@import '../../mixins';

.filterButton {
  border: 1px solid var(--blue-007aff);
  padding: var(--padding-2xs) var(--padding-lg);
  border-radius: var(--br-md);
  display: none;
  color: var(--blue-007aff);
  width: 100%;
}
.filterHeader {
  display: none;
}
.filterButton:hover {
  color: var(--blue-0068d9);
  border: 1px solid var(--blue-0068d9);
}
.filterButton,
.filter2 {
  gap: var(--gap-xs);
}
.filter,
.filterDesktop,
.filter2 {
  width: 100%;
  @include flex($direction: column, $justify: center, $align: center);
}
.filterDesktop {
  gap: var(--gap-lg);
}
.filterMobile {
  display: none;
}
.filter {
  width: 310px;
  flex-shrink: 0;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--exo-2-14-size);
  color: var(--black-000000);
  font-family: var(--exo-2-24-regular);
}
.resetButton {
  color: var(--blue-007aff);
  justify-content: flex-start;
  padding: 0;

  &:hover {
    color: var(--blue-0068d9);
    fill: var(--blue-0068d9);
    stroke: var(--blue-0068d9);
  }
}
@media screen and (max-width: 1024px) {
  .filter {
    width: 100%;
    position: unset;
  }
  .filterButton {
    @include flex($justify: center);
  }
  .filterDesktop {
    display: none;
  }
  .filterMobile {
    position: absolute;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    z-index: 100;
    transition: 0.2s ease-in-out;
    padding: var(--padding-2xs);
    background-color: var(--white-ffffff);
    display: block;

    &_buttons{
      position: fixed;
      padding-bottom: var(--padding-3xs);
      bottom: 0;
      left: var(--padding-3xs);
      right: var(--padding-3xs);
      background: var(--white-ffffff);

      button{
        width: 100%;
        margin-top: var(--padding-3xs);
        justify-content: center;
      }
    }
  }
  .open {
    left: 0;
    padding-top: 0;

    .filterHeader{
      position: fixed;
    }
  }
  .cross {
    padding: 0;
    filter: saturate(0) brightness(1.8);
    transition: 0.3s;
    img {
      width: 24px;
    }
    &:hover {
      filter: saturate(0) brightness(1.4);
    }
  }
  .filterHeader {
    @include flex($justify: space-between);
    font-size: var(--exo-2-16-size);
    margin-bottom: var(--padding-2xs);
    color: var(--black-000000);
    font-weight: 600;
    width: 100%;
    background: var(--white-ffffff);
    z-index: 1;
    padding: var(--padding-2xs) var(--padding-md) var(--padding-3xs) 0;
  }

  .filter2{
    padding-top: 62px;
    gap: 0;
    padding-bottom: 122px;
  }
}
