@import '../../mixins';

.root {
  @include flex();
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--white-ffffff);
  padding: var(--padding-sm);
  gap: var(--gap-2xs);
  cursor: pointer;
}
.content {
  align-self: stretch;
  flex: 1;
}
.question {
  padding-top: var(--padding-8xs);
  font-size: var(--exo-2-20-size);
  align-self: stretch;
  font-weight: 600;
}
.answer {
  align-self: stretch;
  font-size: var(--exo-2-16-size);
  color: var(--gray-52545c);

  p {
    margin-bottom: 0;
    white-space: pre-wrap;
  }
}
.tagheading {
  flex: 1;
  @include flex($direction: column);
  padding: var(--padding-8xs) 0 0;
  gap: var(--gap-md);
}
.icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 16px;
  height: 16px;
}
.accordeonButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--gray-f3f4fb);
  position: relative;
  border-radius: var(--br-3xl);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  transition: background-color 0.2s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transition: 300ms;
    background: var(--gray-81838e);
    border-radius: 2px;
  }

  /* Create the "+" shape by positioning the spans absolutely */
  &::before {
    top: 25%;
    bottom: 25%;
    width: 6%;
    left: 47%;
  }

  &::after {
    left: 25%;
    right: 25%;
    height: 6%;
    top: 47%;
  }
}

.accordeonButton.open {
  /* Morph the shape when the button is hovered over */
  &::before {
    transform: rotate(90deg);
    background: var(--blue-007aff);
  }
  &::after {
    left: 50%;
    right: 50%;
  }
}
.accordeonButton.openHotel {
  /* Morph the shape when the button is hovered over */
  &::before {
    transform: rotate(90deg);
    background: var(--purple-6100FF);
  }
  &::after {
    left: 50%;
    right: 50%;
  }
}

.accordeonButton:hover {
  background-color: var(--gray-e6e8f1);
}
