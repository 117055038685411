.paymentSuccess {

}

.header {
    align-items: center;
}

.successIcon {
    width: 56px;
    height: 56px;
    margin-right: 15px;
}

.manIcon {
    width: 16px;
    height: 16px;
    margin: 5px 0;
}

.title {
    margin: 0 0 5px 0;
    font-size: var(--exo-2-40-size);
    font-weight: 600;
}

.description {
    margin-top: 5px;
    font-size: var(--exo-2-16-size);
    font-weight: 400;
}

.emailLink {
    margin-left: 5px;
    font-size: var(--exo-2-16-size);
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.orderDetails {
    margin-top: 40px;
    justify-content: space-between;
    background-color: var(--white-ffffff);
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    padding: 40px;

}

.guestContainer {
    display: flex;
}

.hotelInfo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
}
.cityContainer{
    display: flex;
}

.city {
    font-size: 20px;
    font-weight: 600;
}
.locationIcon{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.dates {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px

}

.bookingInfo {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.detail {
    display: flex;
    flex-direction: column;
        flex: 1 1 calc(25% - 15px); /* Карточки занимают треть контейнера */
    max-width: calc(25% - 15px); /* Ограничение максимальной ширины */


}

.label {
    font-size: var(--exo-2-12-size);
    font-weight: 400;
    color: var(--gray-a5a7b2);
    margin-bottom: 0;
}

.infoColumn {
    margin: 5px 0;
    font-size: var(--exo-2-14-size);
    font-weight: 400;

}

.statusPay {
    width: 66px;
    height: 24px;
}


.guests {
    margin-top: 20px;
}

.titleGuests {
    font-weight: 600;
    font-size: var(--exo-2-29-size);
    margin-bottom: 15px;
}


.avatarGuest {
    width: 36px;
    height: 36px;
    margin: auto 10px auto 0;
}

.guestList {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.guestCard {
    background-color: var(--white-ffffff);
    padding: 25px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex: 1 1 calc(33.333% - 15px);
    max-width: calc(33.333% - 15px);
    font-weight: 600;
}


@media screen and (max-width: 768px) {
    .guestCard {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
    }
}


@media screen and (max-width: 510px) {
    .guestCard {
        flex: 1 1 calc(100% - 15px);
        max-width: 100%;
    }
    .detail {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
    }
    .title {
        font-size: var(--exo-2-32-size);
    }
    .description{
        font-size: var(--exo-2-14-size);
    }

}
