@import '../../../mixins';

.root {
  @include flex($align: stretch);
  overflow: hidden;
  border-radius: var(--br-3xl);

  box-sizing: border-box;
  width: 166px;
  height: 188px;
  flex-shrink: 0;
  padding: var(--padding-8xs);
  cursor: pointer;
  scrollbar-width: none;

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar {
    background: transparent;
    opacity: 0;
  }
}

.viewed {
  border: 3px solid var(--gray-d5d7e2);
}

.imageChild {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.header {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.gradienttext {
  @include flex($direction: column, $justify: flex-end);
  flex: 1;
  background: linear-gradient(0deg, #000, transparent);
  padding: var(--padding-4xs);
  z-index: 1;
  border-radius: var(--br-2xl);
}
.image {
  @include flex($direction: column, $align: stretch);
  overflow: hidden;
  flex: 1;
  border-radius: var(--br-2xl);
  position: relative;
}
