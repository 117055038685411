@import '../../../../mixins';

.root {
  flex: 1 1;
  border-radius: var(--br-sm);
  position: relative;
  display: contents;

  .inputRoot {
    border: 1px solid transparent;
    padding-right: 0 !important;
    padding-left: 8px !important;
    border: 1px solid var(--gray-d5d7e2);

    input {
      padding-left: 4px;
      width: 100%;
        border-radius: 8px;
    }
  }
}

.iconsArrow{
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.textField {
  width: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0;
  position: relative;
}

.listbox {
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  box-shadow: var(--box-shadow-main);
  width: 330px;
  @include flex($direction: column);
  gap: var(--gap-0);
  margin: 0;
  padding: 0;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;

  /*&::-webkit-scrollbar {
    width: 0;
    display: none;
  }*/
}
.paper {
  z-index: 6;
  width: 340px;
  @include flex($direction: column);
  position: absolute;
  top: 70px;
}

.option {
  cursor: pointer;
  border-radius: var(--br-lg);
  border: 0;
  padding: var(--padding-6xs) var(--padding-xs);
  background-color: var(--white-ffffff);
  align-self: stretch;
  @include flex($justify: center, $align: center);
  gap: var(--gap-5xs);
  transition: background-color 0.2s;

  .value,
  .description {
    align-self: stretch;
    position: relative;
    text-align: left;
    display: inline-block;
  }
  .icon {
    position: relative;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .value {
    font-size: var(--exo-2-14-size);
    color: var(--black-000000);
  }
  .bold {
    font-weight: 600;
    pointer-events: none;
    cursor: default;
  }
  .description {
    font-size: var(--exo-2-12-size);
    color: var(--gray-a5a7b2);
  }
  .title {
    flex: 1;
    @include flex($align: center);
    gap: var(--gap-3xs);
    font-size: var(--exo-2-14-size);
  }
  .code {
    position: relative;
    font-size: var(--exo-2-14-size);
    color: var(--gray-a5a7b2);
    text-align: left;
    display: inline-block;
  }
  &.group {
    cursor: default;
  }
  &:not(.group):hover {
    background-color: rgba(168, 168, 168, .2);
  }
}
.root:hover {
  background-color: var(--gray-f3f4fb);
}
