.PlaceOrderHotelPage {
    background: var(--gray-f3f4fb);
}

.imgHeader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 300px;
    background-image: url("../../../public/images/gradient-hesder-home.png");
    background-size: cover;
}

@import '../../mixins';

.root {
    display: grid;
    grid-template-areas:
    'h h h h'
    'c c c s';
    column-gap: var(--gap-xs);

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.header {
    grid-area: h;
}

.content {
    grid-area: c;
    @include flex($direction: column, $align: stretch);
    gap: var(--gap-xs);
}

.sidebar {
    grid-area: s;
}

.city {
    color: var(--white-ffffff);
    font-weight: 600;
    font-size: var(--exo-2-40-size);
    margin-bottom: 10px;
}

.dates {
    color: var(--white-ffffff);
    font-weight: 500;
    font-size: var(--exo-2-16-size);


}

.containerCityInfo {
    margin-top: 25px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5;
    @media screen and (max-width: 420px){
        padding-left: 20px;

    }
}
.main {
  @include flex($justify: center);
  flex: 1;
  background-color: var(--gray-f3f4fb);
  flex-direction: row;
  padding: var(--padding-lg) 0 var(--padding-5xl);
  gap: var(--gap-xl);

  @media screen and (max-width: 1440px) {
    padding: var(--padding-lg) var(--padding-md) var(--padding-5xl);
  }

  @media screen and (max-width: 1024px) {
    padding: var(--padding-lg) var(--padding-3xs) var(--padding-5xl);
  }
}
.children {
  z-index: 1;
  width: 100%;
}
.content {
  @include flex();
  gap: var(--gap-xl);
  flex: 1;
  align-self: stretch;
  max-width: var(--max-width);
    flex-direction: column;
}
.rootMain {
  @include flex($direction: column, $align: stretch);
  min-height: 100vh;
}
.backButton {
  font-weight: 600;
  align-self: flex-start;
  text-align: left;
  width: auto;
  padding: 0;
  margin: 35px 0 16px 0;
  color: var(--white-ffffff);

  .scrollArrow {
    border-radius: var(--br-xl);
    background-color: var(--color-gray-1100);
    width: 32px;
    height: 32px;
    filter: saturate(0) brightness(2);
    position: relative;

    .icons16px {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    color: var(--white-ffffff)!important ;
    opacity: 0.8;
  }
}



