@import '../../../mixins';

h1, p {
    margin: 0;
}

.payment_modal {

    h1 {
        font-size: var(--exo-2-28-size);
        font-weight: 600;
        margin-bottom: var(--padding-xs);
    }

    &_about {
        &_title {
            font-size: var(--exo-2-16-size);
            font-weight: 600;
            margin-bottom: var(--padding-4xs);
        }

        &_content {
            display: flex;
            flex-wrap: wrap;
        }

        &_item {
            width: 50%;
            text-align: left;

            &_name {
                font-size: var(--exo-2-12-size);
                color: var(--gray-a5a7b2);
                margin-bottom: var(--padding-9xs);
            }

            &_value {
                display: flex;
                font-size: var(--exo-2-14-size);
                margin-bottom: var(--padding-4xs);
                font-weight: 400;
                color: var(--black-000000);
            }
        }
    }

    &_passengers {
        margin-bottom: var(--padding-xs);

        &_expand {
            padding: 0;
            justify-content: flex-start;
            color: #6100FF;
            gap: var(--gap-2xs);

            &.invert img {
                transform: rotate(180deg);
            }
        }

        &_list {
            &_passenger {
                background: var(--gray-f3f4fb);
                border-radius: var(--br-sm);
                padding: var(--padding-4xs);
                margin-bottom: var(--padding-4xs);

                &_name {
                    font-size: var(--exo-2-14-size);
                    font-weight: 600;
                    margin-bottom: var(--padding-9xs);
                    text-transform: uppercase;
                }

                &_info {
                    font-size: var(--exo-2-14-size);
                    color: var(--gray-81838e);
                }
            }
        }
    }

    &_radio {
        margin-bottom: var(--padding-3xs);

        label {
            border: 1px solid var(--gray-d5d7e2);
            padding: var(--padding-4xs) var(--padding-3xs);
            background-color: var(--white-ffffff);
            flex: 1 1;
            border-radius: var(--br-sm);
        }

        input:checked + label {
            border: 1px solid #6100FF;
        }
    }

    &_crypto {
        padding: var(--padding-4xs) var(--padding-3xs);
        background: var(--gray-f3f4fb);
        border-radius: var(--br-sm);

        &_title {
            font-size: var(--exo-2-14-size);
            margin-bottom: var(--gap-6xs);
        }

        &_description {
            font-size: var(--exo-2-12-size);
            color: var(--gray-a5a7b2);
        }

        a {
            border-bottom: 1px solid #6100FF;
            color: #6100FF;
        }
    }

    &_sum {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--padding-xs);


        &_first {
            margin-top: var(--padding-xs);
        }

        &_second {
        }

        &_label {
            font-size: var(--exo-2-14-size);
        }

        &_value {
            font-size: var(--exo-2-24-size);
            font-weight: 600;
        }

        &_delimiter {
            margin: 0;
            font-size: var(--gap-xs);
            color: var(--gray-a5a7b2);
        }
    }

    &_button {
        width: 100%;
        background: #6100FF;

    }
}

.payment_modal_variant_pay_label {
    margin-bottom: var(--padding-xs);
    color: var(--orange-ff7a00);
    font-size: var(--exo-2-14-size);

}

.iconWarning {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;

}

.warning {
    display: flex;
    gap: 3px;
}

.error {
    font-size: var(--exo-2-12-size);
    color: var(--red-ff3d00);
    margin-top: var(--padding-7xs);
}

.checkbox {
    margin-top: var(--padding-7xs);


    a {
        color: var(--gray-a5a7b2);
    }
}

.link {
}

.agreementText {
}

@mixin headerInfo {
    font-size: var(--exo-2-16-size);
    font-weight: 600;
    text-align: left;

}

.headerInfo {
    @include headerInfo;
}

.variantPay {

    @include headerInfo;
    margin: 20px 0;
}

.bookingInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;

}

.bookingGuests {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, padding 0.3s ease;

    &.open {
        max-height: 500px; /* Установите высоту, подходящую для контента */
    }

}

.bookingGuests {
    padding: 0;

}

.detail {
    margin: 10px 0;
    display: flex;
    flex-direction: column;


}

.label {
    font-size: var(--exo-2-12-size);
    font-weight: 400;
    color: var(--gray-a5a7b2);
    margin-bottom: 0;
}

.guestContainer {
    display: flex;
}

.infoColumn {
    margin-top: 5px;
    font-size: var(--exo-2-14-size);
    font-weight: 400;

}

.manIcon {
    width: 16px;
    height: 16px;
    margin: 5px 0;
}

.containerInfo {
    flex: 1 1;
}

.guestsName {
    margin: 10px 0;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background: var(--gray-f3f4fb);
    text-align: center;
    font-size: var(--exo-2-14-size);
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 10px;
}

.toggleButton {
    display: block;
    margin-top: 16px;
    border: none;
    background: none;
    color: #6100FF;
    cursor: pointer;
    padding: 0;
}

.bookingGuests.open {
    max-height: 1000px; /* Для полного разворота */
}

.collapseImg {
    margin-bottom: -3px;
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;

    &.expand {
        margin-bottom: -5px;
        transform: rotate(180deg);
    }

}
