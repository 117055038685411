@import '../../../mixins';

.root {
  @include flex($align: center);
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--exo-2-14-size);
  color: var(--black-000000);
  font-family: var(--exo-2-24-regular);

  .subtitleheading {
    flex: 1;
    @include flex($direction: column, $justify: center);
    gap: var(--gap-3xs);
    background-color: var(--white-ffffff);

    .label,
    .description {
      align-self: stretch;
      position: relative;
      display: inline-block;
    }
    .label {
      font-weight: 600;
    }
    .description {
      font-size: var(--exo-2-12-size);
      color: var(--gray-a5a7b2);
    }
  }

  .quantity {
    @include flex($align: center);
    background-color: var(--white-ffffff);
    width: 100px;
    flex-shrink: 0;
    align-items: center;
    gap: var(--gap-2xs);
    text-align: center;

    .icon {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 16px;
      height: 16px;
    }
    .value,
    .scrollArrow {
      position: relative;
      flex-shrink: 0;
    }
    .scrollArrow {
      cursor: pointer;
      border: 0;
      padding: 0;
      background-color: var(--gray-f3f4fb);
      border-radius: var(--br-xl);
      width: 32px;
      height: 32px;
      overflow: hidden;

      &:disabled {
        img {
          opacity: 0.3;
        }
        &:hover {
          background-color: var(--gray-f3f4fb);
        }
      }
      &:hover {
        background-color: var(--color-gray-300);
      }
    }
    .value {
      font-weight: 600;
      display: inline-block;
      width: 28px;
    }
  }
}
