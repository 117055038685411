@import '../../../mixins';

.root {
  @include flex($direction: column, $align: stretch);
  min-height: 100vh;
}

.main {
  @include flex($justify: center);
  flex: 1;
  background-color: var(--gray-f3f4fb);
  flex-direction: row;
  padding: var(--padding-lg) 0 var(--padding-5xl);
  gap: var(--gap-xl);

  @media screen and (max-width: 1440px) {
    padding: var(--padding-lg) var(--padding-md) var(--padding-5xl);
  }

  @media screen and (max-width: 1024px) {
    padding: var(--padding-lg) var(--padding-3xs) var(--padding-5xl);
  }
}

.main__white {
    background-color: var(--white-ffffff)
}

.content {
  @include flex();
  gap: var(--gap-xl);
  flex: 1;
  align-self: stretch;
  max-width: var(--max-width);
}
.children {
  z-index: 1;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: var(--padding-2xl);
  }
}
@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    padding-bottom: var(--padding-5xl);
  }
  .content {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .main {
    padding-top: var(--padding-sm);
    padding-bottom: var(--padding-sm);
  }
}
