.container {
    display: flex;
    grid-gap: 10px;
    margin: 20px 0;
    flex-wrap: wrap;
}

.header {
    font-size: var(--exo-2-20-size);
    margin-top: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.imgAvatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.input{
    min-width: 300px;
    flex: 1 1;
}
