@import '../../mixins';

.root {
  @include flex($direction: column, $justify: center, $align: center);
  border-radius: var(--br-lg);
  padding: var(--padding-5xl) var(--padding-3xl) 0;
  gap: var(--gap-xl);
  width: 100%;

  .button {
    align-self: center;
    box-shadow: var(--search-field);
    padding: var(--padding-xs) var(--padding-md);
  }
}
@media screen and (max-width: 1024px) {
  .root {
    padding: 0;
  }
}
