@import '../../../mixins';
.root {
  @include flex($direction: column, $justify: center);
  gap: var(--gap-6xs);
  align-self: stretch;
}
.tabButtons {
  @include flex($justify: center, $align: center);
  align-self: stretch;
  border-radius: var(--br-lg);
  overflow: hidden;
  padding: var(--padding-9xs);
  gap: var(--gap-0);
  background-color: var(--gray-eef0f6);
}
.title {
  font-size: var(--exo-2-14-size);
  color: var(--gray-a5a7b2);
}
.input {
  display: none;
}
.error {
  color: var(--red-ff3d00);
  font-weight: 400;
  font-size: var(--exo-2-12-size);
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-left: 14px;
}
.label {
  flex: 1;
  cursor: pointer;
  font-size: var(--exo-2-12-size);
  font-weight: 600;
  background-color: var(--gray-eef0f6);
  color: var(--gray-a5a7b2);
  text-align: center;
  padding: var(--padding-7xs) var(--padding-xs);
  border-radius: var(--br-md);
  transition: 0.2s;
}
.input:checked + .label {
  background-color: var(--white-ffffff);
  color: var(--black-000000);
}
.label:hover {
  box-shadow: var(--card);
}
