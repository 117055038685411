@import '../../../mixins';

.filter {
  box-shadow: 0 2px 12px rgba(68, 48, 95, 0.05);
  border-radius: var(--br-md);
  background-color: var(--white-ffffff);
  align-self: stretch;
  @include flex($direction: column, $align: stretch);
  padding: var(--padding-xs);

  text-align: left;
  font-size: var(--exo-2-14-size);
  color: var(--gray-52545c);

  @media screen and (max-width: 1024px) {
    box-shadow: none;
    border-bottom: 1px solid var(--gray-eef0f6);
    padding: var(--padding-xs) 0;

    &:last-child{
      border-bottom: 0;
    }
  }

  .nameFilter {
    align-self: stretch;
    @include flex($align: center);
    gap: var(--gap-xs);
    color: var(--black-000000);

    .name {
      flex: 1;
      font-weight: 600;
    }
    .button {
      padding: 0 !important;
      transition: 0.6s;
      filter: grayscale(100%);
    }
    .invert {
      transform: rotateX(180deg);
    }
  }
  .content {
    margin-top: var(--gap-sm);
    align-self: stretch;
  }
}
