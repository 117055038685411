.mobile_menu{
  position: absolute;
  left: 0;
  right: 0;
  top: 62px;
  background: var(--white-ffffff);
  display: flex;
  flex-direction: column;

  nav{
    flex: 1;
    padding: 32px 16px;

    .link{
      margin-bottom: 16px;

      a{
        color: var(--black-000000);
        text-decoration: none;
        font-size: var(--exo-2-14-size);
        font-weight: 500;
      }
    }
  }

  &_btn{
      border: 1px solid var(--blue-007aff);
      color: var(--blue-007aff);
      width: 100%;
      margin-top: var(--padding-sm);

    img{
      filter: none !important;
    }


  }
}