@import '../../../mixins';

.root {
    flex: 1 1;

    .inputRoot {
        padding-right: 0 !important;
        padding-left: 8px !important;
    }

    & > *:last-of-type {
        border: 1px solid transparent;
    }

    &:focused {
        border: none !important;
    }
}


.withError input {
    opacity: 0;
}

.withErrorPlaceholderEnd {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    font-size: 16px;
    color: var(--red-ff3d00);
    pointer-events: none;
}

.seconDateContainer {
    position: relative;
    flex: 1 1;
    border: none;
}

.withErrorPlaceholder {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    font-size: 16px;
    color: var(--red-ff3d00);
    pointer-events: none;
}

.withError > div:nth-child(2) > div {
    border: 1px solid var(--red-ff3d00) !important;
    border-radius: 0.5rem;
}

.withErrorPlaceholder:nth-of-type(2) {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    font-size: 16px;
    color: var(--red-ff3d00);
    pointer-events: none;
}

.textField {
    width: 100%;
    cursor: pointer;
    border: 0;
    padding: 0;
    position: relative;
}

.DatePickerWrapper {
    width: 100%;
}

.DatePicker {
    @include flex($align: stretch);
    align-self: stretch;
    flex: 1;
    position: relative;
}

.divider {
    height: 35px;
    width: 1px;
    background-color: var(--gray-d5d7e2);
    align-self: center;
}

.paper {
    border-radius: var(--br-lg);
    box-shadow: var(--box-shadow-main);
    z-index: 100;
}

.popper {
    z-index: 5;
}

.popperBox {
    @include flex($direction: column, $justify: center, $align: center);
    gap: var(--gap-md);
    background-color: var(--white-ffffff);
    padding: var(--padding-xs);
    border-radius: 12px;
}

.calendarBox {
    border-radius: 12px;
    padding-top: 0;
    background-color: var(--white-ffffff);
    @include flex($justify: center, $align: flex-start);

    .MuiPickersCalendarHeader-root {
        display: 'flex';
        align-items: 'center';
        justify-items: 'center';
    }

    .MuiPickersCalendarHeader-root:first-child {
        order: 1;
    }

    .MuiPickersCalendarHeader-root:last-child {
        order: 0;
    }
}

.reverseTicketButton {
    width: 50%;

    &:disabled {
        color: var(--gray-a5a7b2);
        background-color: var(--gray-f3f4fb);
    }
}

.DatePickerPopperWrapper {
    display: none;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: var(--padding-sm) var(--padding-sm) 0;
}

.containerDatePickerHeader {
    width: 100%;
    position: relative;
    @media screen and (max-width: 740px){
        max-width: 440px;

    }
}

.plugDatePickerHeader {
    position: absolute;
    width: 80%;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
}

.cross {
    padding: 0;
    filter: saturate(0) brightness(1.8);
    transition: 0.3s;

    img {
        width: 24px;
    }

    &:hover {
        filter: saturate(0) brightness(1.4);
    }
}

.labelContainer {
    @include flex($justify: center);
}

.SwitchViewButtons {
    @include flex($align: center);
    gap: var(--padding-9xs);

    button {
        padding: 0;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        color: var(--black-222222);

        &:not(:disabled):hover,
        &:hover,
        &:active,
        &:focus {
            color: var(--black-222222);
        }
    }
}

.CalendarHeader {
    width: 100%;
    @include flex($justify: space-between, $align: center);

    .CalendarNavButton {
        filter: grayscale(1) brightness(0);
        padding: var(--padding-9xs) var(--padding-xs);

        &:disabled {
            filter: grayscale(1) brightness(2);
        }
    }
}

@media screen and (max-width: 740px) {
    .DatePickerPopperWrapper {
        display: flex;
        border-radius: var(--br-md);
        margin-top: 35px;
        border: 1px solid var(--gray-33343a);
    }

    .CalendarHeader {
        max-width: 440px;
    }

    .paper {
        top: 74px !important;
    }
}

[class*='RangeDatePicker_SwitchViewButtons'] > button {
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .reverseTicketButton {
        width: unset;
    }
}
