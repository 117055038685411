@import '../../../mixins';

.stories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  padding: var(--padding-4xl) 0;
  font-size: var(--exo-2-14-size);
  color: var(--white-ffffff);
  overflow-x: scroll;
  gap: var(--gap-xl);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
    >* {
        border: 3px solid var(--blue-007aff);
    }
    &.hotels > *{
        border: 3px solid var(--purple-6100FF);
    }
}

@media screen and (max-width: 1024px) {
  .stories {
    padding: 0 var(--padding-3xs);
  }
}
