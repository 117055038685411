.iconWhite,
.iconBlack {
  position: relative;
  width: 162.25px;
  height: 28px;
  flex-shrink: 0;
  overflow: hidden;
}

.iconBlack {
  filter: brightness(0);
}
