@import '../../mixins';

.root {
  flex: 1 1;
  border-radius: var(--br-sm);
  position: relative;

  .inputRoot {
    border: 1px solid transparent;
    padding-right: 0 !important;
    padding-left: 8px !important;

    input {
      padding-left: 4px;
    }
  }
}

.withError > div > div{
  border: 1px solid var(--red-ff3d00) !important;
  border-radius: 0.5rem;
}

.withError input{
    opacity: 0;
}
.withErrorPlaceholder{
    position: absolute;
    bottom: 0.75rem;
    left: 0.8rem;
    font-size: 16px;
    color: var(--red-ff3d00);
    pointer-events: none;
}

.textField {
  width: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0;
  position: relative;
}

.listbox {
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  box-shadow: var(--box-shadow-main);
  width: 340px;
  @include flex($direction: column);
  gap: var(--gap-0);
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}
.paper {
  z-index: 6;
  width: 340px;
  @include flex($direction: column);
  position: absolute;
  top: 70px;
}

.option {
  cursor: pointer;
  border-radius: var(--br-lg);
  border: 0;
  padding: var(--padding-2xs) var(--padding-xs);
  background-color: var(--white-ffffff);
  align-self: stretch;
  @include flex($justify: center, $align: center);
  gap: var(--gap-xs);
  transition: background-color 0.2s;

  .value,
  .description {
    align-self: stretch;
    position: relative;
    text-align: left;
    display: inline-block;
  }
  .icon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .value {
    font-size: var(--exo-2-14-size);
    color: var(--black-000000);
    font-weight: 600;
  }
  .bold {
    font-weight: 600;
  }
  .description {
    font-size: var(--exo-2-12-size);
    color: var(--gray-a5a7b2);
  }
  .title {
    flex: 1;
    @include flex($direction: column, $justify: center);
    gap: var(--gap-3xs);
    font-size: var(--exo-2-14-size);
  }
  .code {
    position: relative;
    font-size: var(--exo-2-14-size);
    color: var(--gray-a5a7b2);
    text-align: left;
    display: inline-block;
  }
  &:hover {
    background-color: rgba(168, 168, 168, .2);
  }
}
.root:hover {
  background-color: var(--gray-f3f4fb);
}
