@import '../../mixins';

.page {
  position: relative;
  cursor: pointer;
  font-size: var(--exo-2-14-size);
  text-align: center;
  font-weight: 600;
  border: 0;
  background-color: var(--white-ffffff);
  color: var(--black-000000);
  border-radius: var(--br-xs);
  overflow: hidden;
  @include flex($direction: column, $align: center, $justify: center);
  transition: background-color 0.1s;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: var(--gray-f3f4fb);
  }
}
.active {
  background-color: var(--black-222222);
  color: var(--white-ffffff);
  &:hover {
    background-color: var(--black-222222);
    color: var(--white-ffffff);
  }
}
.pagination {
  margin-top: var(--padding-xl);
  @include flex();
  gap: var(--gap-3xs);
}
