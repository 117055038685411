@import '../../mixins';

.rectangleDiv {
  flex: 1;
  position: relative;
  background-color: var(--gray-d5d7e2);
  width: 1px;
}
.div,
.div1 {
  position: relative;
  display: inline-block;
}
.div {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.div1 {
  font-size: var(--exo-2-12-size);
  color: var(--gray-a5a7b2);
}
.subtitleheading {
  flex: 1;
  overflow: hidden;
  @include flex($direction: column, $justify: center);
  gap: var(--gap-3xs);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.fieldsData {
  align-items: center;
  gap: var(--gap-0);
  align-self: stretch;
  @include flex();
}

.searchItem {
  position: relative;
}
.divider {
  height: 35px;
  width: 1px;
  background-color: var(--gray-d5d7e2);
  align-self: center;
}
.hiddenInMobile {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.swipeButton {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #F3F4FB;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 4px;
    z-index: 1;

    &:disabled {
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: 1024px) {
    & {
      position: relative;
      border-bottom: 1px solid var(--gray-d5d7e2);
      width: calc(100% - 72px);
      align-self: flex-start;
      left: 1.5%;

      button {
        position: absolute;
        top: 50%;
        right: -48px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
}
.searchField {
  // max-width: var(--max-width);
  // padding: 0 var(--padding-4xl);
  width: 100%;
  align-self: center;
  @include flex($justify: center, $align: center);
  gap: var(--gap-2xs);
  position: sticky;
  text-align: left;
  font-size: var(--exo-2-16-size);
  color: var(--black-000000);
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding: 0 var(--padding-3xs) !important;
  }

  .searchButton {
    width: 164px;
  }
  .searchButtonMobile {
    display: none;
  }

  .searchDesktop {
    padding-right: 1px;
    flex: 1;
    @include flex($justify: center, $align: center);
    border-radius: var(--br-md);
    box-shadow: var(--search-field);
    background-color: var(--white-ffffff);
    & .searchItem {
      align-self: stretch;
      @include flex($justify: center, $align: center);
      flex: 1;
    }
    & .datepicker {
      flex: 2;
    }
  }
  .searchMobile {
    flex: 1;
    border-radius: var(--br-md);
    box-shadow: var(--search-field);
    background-color: var(--white-ffffff);
    display: none;
    padding: var(--padding-xs);

    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    gap: var(--gap-xs);
  }
  .searchMobileExpand {
    flex: 1;
    border-radius: var(--br-md);
    background-color: var(--white-ffffff);
    box-shadow: var(--search-field);
    align-self: stretch;
    display: none;
    gap: var(--gap-0);
    font-size: var(--exo-2-12-size);
    .searchItem {
      width: 100%;
    }
    .divider {
      width: 97%;
      height: 1px;
    }
  }
}
@media screen and (max-width: 1560px) {
  .searchField {
  }
}

@media screen and (max-width: 1300px) {
  .searchField {
    max-width: unset;
  }
}
@media screen and (max-width: 1024px) {
  .searchField {
    padding: 0;
  }
}
@media screen and (max-width: 1024px) {
  .searchField {
    flex-direction: column;
    .searchDesktop,
    .searchButton {
      display: none;
    }
    .searchMobile,
    .searchButtonMobile {
      @include flex($justify: center, $align: center);
    }

    .searchMobileExpand {
      @include flex($direction: column, $justify: center, $align: center);
    }

    .searchButtonMobile {
      margin-top: 0.3rem;
      width: 100%;
    }
  }
}
