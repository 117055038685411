.containerButton {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    margin-top: 20px;
}

.button {
    min-width: 158px;

}


.header {
    align-items: center;
}

.successIcon {
    width: 56px;
    height: 56px;
    margin-right: 15px;
}


.title {
    margin: 0 0 5px 0;
    font-size: var(--exo-2-40-size);
    font-weight: 600;
    @media screen  and (max-width: 500px){
        font-size: var(--exo-2-32-size);
    }
}

.description {
    margin-top: 5px;
    font-size: var(--exo-2-16-size);
    font-weight: 400;
}


.windowWrapper {
    margin-top: 40px;
    justify-content: space-between;
    background-color: var(--white-ffffff);
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    padding: 40px;

}


.detail {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(25% - 15px); /* Карточки занимают треть контейнера */
    max-width: calc(25% - 15px); /* Ограничение максимальной ширины */


}

.label {
    font-size: var(--exo-2-12-size);
    font-weight: 400;
    color: var(--gray-a5a7b2);
    margin-bottom: 0;
}


@media screen and (max-width: 510px) {
    .windowWrapper {
        padding: 20px;
    }
    .button {
        flex: 1 1 auto;
    }

}
