@import '../../../mixins';

.root {
  @include flex($align: center);
  flex: 1;

  .input {
    width: 20px;
    height: 20px;
    display: none;
  }

  .icons20px {
    width: 20px;
    height: 20px;
    overflow: hidden;
  }

  .label {
    cursor: pointer;
    @include flex($align: center);
    gap: var(--gap-6xs);
    font-size: var(--exo-2-14-size);
    color: var(--black-000000);
    text-align: left;
    gap: var(--gap-3xs);
    transition: 0.2s;

    .column {
      @include flex($direction: column);
    }

    .description {
      font-size: var(--exo-2-12-size);
      color: var(--gray-a5a7b2);
    }
  }
  .error {
    border: 1px solid var(--red-ff3d00) !important;
  }

  .input + .label:before {
    content: ' ';
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--gray-d5d7e2);
    margin-right: var(--padding-8xs);
    box-sizing: border-box;
    transition: 0.2s;
  }
  .input:checked + .label:before {
    border: 6px solid var(--blue-007aff);
  }
    &.hotels {
    .input:checked + .label:before {
      border: 6px solid var(--purple-6100FF);
    }
  }
}
