@import '../../mixins';

.root {
  @include flex($direction: column, $justify: center, $align: center);
  align-self: stretch;
  padding: 0 0 var(--padding-lg);
  gap: var(--gap-lg);
  font-size: var(--exo-2-24-size);

  .rectangleDiv,
  .slider {
    border-radius: var(--br-xs);
    height: 6px;
  }
  .slider {
    align-self: stretch;
    position: relative;
    background-color: var(--blue-bfdeff);
    flex-shrink: 0;
    overflow: hidden;

    .rectangleDiv {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--blue-007aff);
    }
  }
}
