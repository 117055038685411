@import '../../../mixins';

.wrapper {
  position: relative;
}
.box {
  width: 100%;
  @include flex($direction: column, $justify: center, $align: center);
  gap: var(--gap-lg);
}
.dropdown {
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  box-shadow: var(--box-shadow-main);
  padding: var(--padding-md);
  width: 320px;
  z-index: 10;
  text-align: left;
  font-size: var(--exo-2-14-size);
  color: var(--black-000000);
  font-family: var(--exo-2-24-regular);
}
