@import '../../../mixins';

.buttonIconLight,
.buttonIconDark {
  border: none;
  text-decoration: none;
  background-color: transparent;
  outline: none;

  @include flex($justify: center, $align: center);
  padding: var(--padding-7xs) var(--padding-3xs);
  gap: var(--gap-xs);
  cursor: pointer;
  text-align: left;
  color: var(--white-ffffff);
  font-size: var(--exo-2-14-size);
  font-family: var(--exo-2-24-regular);

  .text,
  .icon {
    position: relative;
  }
  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .text {
    font-weight: 600;
    display: inline-block;
  }

  &:hover {
    opacity: 0.5;
  }
}
.buttonIconLight {
  border: none;
  color: var(--blue-007aff);
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 768px) {
  .buttonIconLight,
  .buttonIconDark {
    padding: var(--padding-7xs) var(--padding-7xs);

    .text {
      display: none;
    }
  }

  .mobile{
    .text {
      display: block;
    }
  }
}
