.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    text-align: left;
    position: absolute;
    top: 0;
    background-color: var(--white-ffffff);
    width: 100%;
    align-items: flex-end;
    font-size: var(--exo-2-48-size);
    color: var(--black-000000);
}

.gradientHome,
.headerhome1Icon {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    overflow: hidden;
}

.headerhome1Icon {
    height: 100%;
    top: 11px;
    bottom: -11px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.gradientHome {
    margin: 0 !important;
    bottom: 0;
    height: 768px;
    flex-shrink: 0;
    z-index: 0;
    border-radius: 0 0 var(--br-3xl) var(--br-3xl);
}

.a,
.icon {
    position: relative;
}

.icon {
    width: 162.25px;
    height: 28px;
    flex-shrink: 0;
    overflow: hidden;
}

.a {
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    transition: color 0.2s;
}

.a:hover {
    color: var(--blue-007aff);
}

.link,
.link1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.link {
    gap: var(--gap-3xl);
}

.icons24px {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
}

.div1 {
    position: relative;
    font-weight: 600;
}

.buttonIcon {
    border-radius: var(--br-3xl);
    border: 1px solid var(--white-ffffff);
    display: flex;
    flex-direction: row;
    padding: var(--padding-7xs) var(--padding-3xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    cursor: pointer;
}

.vector194Stroke,
.vector195Stroke,
.vector196Stroke {
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% - 12px);
    width: 24px;
    height: 2px;
}

.vector195Stroke,
.vector196Stroke {
    top: calc(50% - 1px);
}

.vector196Stroke {
    top: calc(50% + 7px);
}

.icons24px1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.buttonHeader,
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttonHeader {
    justify-content: flex-start;
    gap: var(--gap-xl);
    font-size: var(--exo-2-14-size);
}

.header {
    align-self: stretch;
    padding: var(--padding-3xs) var(--padding-4xl);
    justify-content: space-between;
    position: sticky;
    text-align: left;
    font-size: var(--exo-2-16-size);
    color: var(--white-ffffff);
    font-family: var(--exo-2-14-semibold2);
}

.p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.p1 {
    margin: 0;
}

.div2,
.h1 {
    align-self: stretch;
    position: relative;
}

.h1 {
    margin: 0;
    font-size: 72px;
    line-height: 102%;
    font-weight: 600;
    font-family: inherit;
}

.div2 {
    font-size: var(--exo-2-20-size);
}

.container {
    width: 100%;
    align-self: center;
    max-width: 1440px;

    @media screen and (max-width: 1440px) {
        padding: var(--padding-md) var(--padding-md) 0;
    }

    @media screen and (max-width: 1024px) {
        padding: var(--padding-3xs) var(--padding-3xs) 0;
    }
}

.headingsubtitle {
    align-self: center;
    display: flex;
    flex-direction: column;
    // padding: var(--padding-6xl) var(--padding-4xl) var(--padding-4xl);
    padding: var(--padding-6xl) 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-md);
}

.div3 {
    position: relative;
}

.div4,
.subtitleheading {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.div4 {
    position: relative;
    font-size: var(--exo-2-16-size);
    font-weight: 500;
    color: var(--black-000000);
}

.subtitleheading {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-6xs);
}

.icons16px {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.field1 {
    flex: 1;
    border-radius: var(--br-sm) 0 0 var(--br-sm);
    background-color: var(--white-ffffff);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.lineChild {
    flex: 1;
    position: relative;
    background-color: var(--gray-d5d7e2);
    width: 1px;
}

.field,
.field2,
.line {
    background-color: var(--white-ffffff);
    display: flex;
    justify-content: flex-start;
}

.line {
    align-self: stretch;
    flex-direction: column;
    padding: var(--padding-4xs) 0;
    align-items: flex-start;
}

.field,
.field2 {
    flex: 1;
    border-radius: var(--br-sm);
    flex-direction: row;
    align-items: center;
}

.field2 {
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
}

.field {
    box-shadow: var(--search-field3);
}

.div13,
.div14 {
    position: relative;
}

.div13 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.div14 {
    font-size: var(--exo-2-12-size);
    color: var(--gray-a5a7b2);
}

.icons20px {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.div15,
.field6 {
    font-size: var(--exo-2-16-size);
}

.field6 {
    flex: 1;
    border-radius: var(--br-sm);
    background-color: var(--white-ffffff);
    box-shadow: var(--search-field3);
    display: none;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    color: var(--black-000000);
}

.div15 {
    position: relative;
    font-weight: 600;
    font-family: var(--exo-2-14-semibold2);
    color: var(--white-ffffff);
    text-align: center;
}

.button,
.field7 {
    align-self: stretch;
    border-radius: var(--br-sm);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    cursor: pointer;
    border: 0;
    padding: var(--padding-2xs) var(--padding-sm);
    background-color: var(--blue-007aff);
    box-shadow: var(--search-field3);
    width: 164px;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
    transition: background-color 0.2s;
}

.button:hover {
    background-color: var(--color-indigo-200);
}

.field7 {
    background-color: var(--white-ffffff);
    padding: var(--padding-3xs);
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.lineChild1 {
    flex: 1;
    position: relative;
    background-color: var(--gray-d5d7e2);
    height: 1px;
}

.fieldsData,
.line4 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.line4 {
    background-color: var(--white-ffffff);
    padding: 0 var(--padding-3xs);
    align-items: flex-start;
}

.fieldsData {
    align-items: center;
}

.fieldsearchmobile {
    flex: 1;
    border-radius: var(--br-sm);
    background-color: var(--white-ffffff);
    box-shadow: var(--search-field3);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.searchFieldbutton {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-4xl);
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    position: sticky;
}

.searchDropdown {
    font-size: var(--exo-2-12-size);
    color: var(--gray-a5a7b2);
}

.headingsearchField,
.home,
.searchDropdown {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.headingsearchField {
    padding: 0 0 124px;
    z-index: 1;
}

.home {
    position: relative;
    gap: var(--gap-xl);
    font-size: var(--exo-2-96-size);
    color: var(--white-ffffff);
    z-index: 2;
}

.h2 {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
}

.span {
    color: var(--purple-6100FF);
    text-decoration: none;
}

.heading {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0 0 var(--padding-md);
    align-items: flex-start;
    justify-content: flex-start;
}

.div34 {
    font-weight: 600;
}

.div34,
.div35,
.div36 {
    align-self: stretch;
    position: relative;
}

.div35 {
    font-size: var(--exo-2-32-size);
    font-weight: 600;
    color: var(--black-000000);
}

.div36 {
    color: var(--gray-52545c);
}

.headingsubtitle1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.imagestepIcon {
    position: relative;
    width: 380px;
    object-fit: contain;
    flex-shrink: 0;
    overflow: hidden;
    aspect-ratio: 1.46;
    max-width: 100%;
}

.stepCards,
.stepcards {
    align-self: stretch;
    display: flex;
}

.step1 {
    background-color: var(--gray-f3f4fb);
}

.step2 {
    background-color: #EFE5FF;
}

.stepcards {
    flex: 1;
    border-radius: var(--br-xl);

    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-md) var(--padding-md) 0;
    align-items: center;
    justify-content: space-between;
}

.stepCards {
    flex-direction: row;
    padding: 0 0 var(--padding-sm);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-bottom: var(--padding-3xs);
        box-sizing: border-box;
        gap: var(--gap-md);

    }
}

.imagestepIcon2 {
    align-self: center;
    position: relative;
    overflow: hidden;
    width: 240px;
    height: 180px;
    flex-shrink: 0;
    object-fit: contain;
    aspect-ratio: 1.46;
    margin-bottom: -20px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-bottom: 20px;
    }
}


.stepcards2 {
    flex: 1;
    border-radius: var(--br-xl);
    background-color: var(--gray-f3f4fb);
    height: 440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-md);
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;

}

.div44,
.div45 {
    align-self: stretch;
    position: relative;
}

.div44 {
    font-size: var(--exo-2-32-size);
    font-weight: 600;
}

.div45 {
    color: var(--white-ffffff);
}

.daysIcon {
    position: relative;
    width: 240px;
    height: 180px;
    flex-shrink: 0;
    object-fit: contain;
    aspect-ratio: 1;
    max-width: 100%;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        width: 220px;
        height: 160px;

    }
}

.stepcards3 {
    flex: 1;
    border-radius: var(--br-xl);
    background-color: var(--purple-6100FF);
    height: 440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 32px 32px 10px 32px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    color: var(--white-ffffff);
    @media screen and (max-width: 768px) {
        padding: 32px;

    }


}

.stepcardsParent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);

    @media screen and (max-width: 420px) {
        flex-direction: column;
    }
}

.div47,
.div48 {
    align-self: stretch;
    position: relative;
}

.div47 {
    font-size: var(--exo-2-32-size);
    font-weight: 600;
}

.div48 {
    color: var(--gray-d5d7e2);
}

.headingsubtitle5 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-md) var(--padding-md) 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}
.imagegradientIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
    aspect-ratio: 1.03;
    rotate: -5deg;
    top: 50px;
}

.stepcards4 {
    flex: 1;
    border-radius: var(--br-xl);
    background-image: url("../../../public/images/image+gradient-fourth-step.png");
    background-size: cover;
    height: 440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--white-ffffff);
    position: relative;

    @media screen and (max-width: 850px) {
      flex: unset;
      align-self: stretch;
    }
}

.cards,
.div33,
.stepCards1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.stepCards1 {
    flex-direction: row;
    gap: var(--gap-xl);
    @media screen and (max-width: 850px) {
        flex-direction: column;
        gap: var(--gap-md);
    }
}

.cards,
.div33 {
    flex-direction: column;
}

.cards {
    text-align: center;
    font-size: var(--exo-2-16-size);
    color: var(--purple-6100FF);
}

.div33 {
    padding: var(--padding-4xl) 0;

    @media screen and (max-width: 1024px) {
        padding: 86px 0 var(--padding-lg) 0;
    }
}

.h21 {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
}

.heading1 {
    width: 328px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-sm) 0 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
}

.blankLine {
    display: block;
}

.p6 {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: var(--design-18-book-size);
    font-family: var(--design-18-book);
    display: none;
}

.tagheading1 {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-xl);
}

.questions,
.questions1,
.tagheading1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.questions1 {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-3xs);
    font-size: var(--exo-2-20-size);
}

.questions {
    gap: var(--gap-md);
    align-self: stretch;
    border-radius: var(--br-3xl);
    background-color: var(--gray-f3f4fb);
    flex-direction: row;
    width: 100vw;
    margin-left: calc((1440px - 100vw) / 2);
    padding: var(--padding-4xl) calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1440px) {
        width: unset;
        padding: var(--padding-sm);
        margin-left: calc(-1 * var(--padding-md));
        margin-right: calc(-1 * var(--padding-md));
    }

    @media screen and (max-width: 1024px) {
        margin-left: calc(-1 * var(--padding-3xs));
        margin-right: calc(-1 * var(--padding-3xs));
    }

    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.h22 {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
}

.imagegradientIcon1 {
    position: relative;
    width: 421px;
    height: 400px;
    flex-shrink: 0;
    overflow: hidden;
    object-fit: cover;
    z-index: 0;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}

.div55,
.div56 {
    align-self: stretch;
    position: relative;
}

.div55 {
    line-height: 102%;
    font-weight: 600;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
        font-size: var(--exo-2-24-size);
    }
}

.div56 {
    font-size: var(--exo-2-16-size);
    color: var(--gray-d5d7e2);
}

.headingsubtitle6 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    @media screen and (max-width: 1024px) and (min-width: 640px) {
        flex-direction: row;
        align-items: flex-start;
    }

}

.imageQuestions {
    width: 240px;
    height: 144px;
    margin-right: 130px;
    @media screen and (max-width: 1024px) {
        margin-right: 0;
    }
    @media screen and (max-width: 640px) {
        margin-right: auto;
        margin-left: 0;
    }

}

.button1 {
    padding: var(--padding-xs) var(--padding-md);
    width: 200px;
    margin-top: auto;
    background: var(--purple-6100FF);


    @media screen and (max-width: 640px) {
        width: 100%;
    }

}

.headingbutton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.questions2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url("../../../public/images/image+gradient-questions.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.headingbutton {
    align-self: stretch;
    flex: 1;
    padding: var(--padding-md);
    align-items: flex-start;
    position: absolute;
    z-index: 1;
    height: 100%;

    @media screen and (max-width: 768px) {
        padding: var(--padding-sm);
    }
}

.questions2 {
    border-radius: var(--br-lg);
    background-color: var(--black-222222);
    width: 421px;
    height: 400px;
    flex-shrink: 0;
    overflow: hidden;
    align-items: center;
    position: relative;
    margin-top: 0;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 253px;
    }
    @media screen and (max-width: 768px) {
        height: 223px;
    }
    @media screen and (max-width: 640px) {
        height: 400px;
    }


}

.div59 {
    align-self: stretch;
    position: relative;
    font-size: var(--exo-2-16-size);
    color: var(--black-222222);
}

.headingsubtitle7 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--padding-sm);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.iconButton {
    margin: -15px;
    position: relative;
    border-radius: var(--br-sm);
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    background-color: var(--purple-6100FF);
    padding: var(--padding-3xs);

}

.headingbutton1,
.socialNetworks {
    display: flex;
    align-items: flex-start;
}

.socialNetworks {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.headingbutton1 {
    flex: 1;
    height: 400px;
    flex-direction: column;
    padding: var(--padding-md) 0 var(--padding-md) var(--padding-md);
    box-sizing: border-box;
    justify-content: flex-end;
    @media screen and (max-width: 1024px) {
        padding: var(--padding-sm) 0 var(--padding-sm) var(--padding-3xs);
    }
}

.mocupTelegramIcon {
    position: relative;
    width: 350px;
    height: 360px;
    flex-shrink: 0;
    overflow: hidden;
    aspect-ratio: 0.97;
    max-width: 100%;
    vertical-align: bottom;
}

.image7 {
    height: 400px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-md) 0 0;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: center;
    vertical-align: bottom;

    @media screen and (max-width: 768px) {
        margin: auto;
        padding-top: 0;
        align-items: flex-end;
    }
}

.help,
.helpCard,
.telegram {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.telegram {
    flex: 1;
    border-radius: var(--br-lg);
    background-color: var(--gray-f3f4fb);
    overflow: hidden;
    flex-direction: row;
    color: var(--black-000000);

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.help,
.helpCard {
    align-self: stretch;
}

.helpCard {
    flex-direction: row;
    gap: var(--gap-xl);
    font-size: var(--exo-2-32-size);
    color: var(--white-ffffff);

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
}

.help {
    flex-direction: column;
    padding: var(--padding-4xl) 0;
}

.scrollArrowIcon {
    position: relative;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    overflow: hidden;
}

.rotated {
    transform: rotate(180deg);
}

.headingscroll,
.scrollArrow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.headingscroll {
    align-self: stretch;
    padding: 0 0 var(--padding-md);
    gap: var(--gap-xl);
}

.icon56px {
    position: relative;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}

.arrowFuther1 {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.arrowFuther2,
.feedback3,
.feedbacks {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.arrowFuther2 {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.feedback3,
.feedbacks {
    align-items: flex-start;
}

.feedback3 {
    flex: 1;
    border-radius: var(--br-lg);
    background-color: var(--white-ffffff);
    flex-direction: column;
    padding: var(--padding-md);
    gap: var(--gap-md);
}

.feedbacks {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: var(--gap-xl);
    font-size: var(--exo-2-14-size);
    color: var(--gray-d5d7e2);
    scrollbar-width: none;

    @media screen and (max-width: 768px) {
        padding-left: calc(1 * var(--padding-sm));
        padding-right: calc(1 * var(--padding-sm));
        margin-left: calc(-1 * var(--padding-sm));
        margin-right: calc(-1 * var(--padding-sm));
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.div69 {
    position: relative;
    font-size: var(--exo-2-16-size);
    font-weight: 600;
    font-family: var(--exo-2-14-semibold2);
    color: var(--gray-33343a);
    text-align: center;
}

.icons12px {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.button3 {
    margin-top: var(--padding-lg);
    padding: var(--padding-2xs) var(--padding-sm);
    color: var(--gray-33343a);
    align-self: flex-start;

    img {
        filter: grayscale(1) brightness(0.5);
    }
}

.div70,
.feedback {
    align-self: stretch;
}

.feedback {
    border-radius: var(--br-3xl);
    background-color: var(--gray-f3f4fb);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    margin-left: calc((1440px - 100vw) / 2);
    padding: var(--padding-4xl) calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1440px) {
        width: unset;
        padding: var(--padding-4xl) var(--padding-sm);
        margin-left: calc(-1 * var(--padding-md));
        margin-right: calc(-1 * var(--padding-md));
    }

    @media screen and (max-width: 1024px) {
        .feedbacks > :last-child {
            display: none !important;
        }
        margin-left: calc(-1 * var(--padding-3xs));
        margin-right: calc(-1 * var(--padding-3xs));
        padding: var(--padding-lg) var(--padding-sm);
    }

    @media screen and (max-width: 768px) {
        margin: 0;
    }

    @media screen and (max-width: 420px) {
        .homeFeedback {
            min-width: 348px !important;
            min-height: 300px;
        }
    }
}

.homeFeedback {
    min-width: 300px !important;
}

.div70 {
    position: relative;
}

.logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.a5 {
    flex: 1;
    position: relative;
    color: inherit;
    text-decoration: none;
    transition: color 0.2s;
}

.a5:hover {
    color: var(--gray-eef0f6);
}

.link7 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.frameLink1,
.link6 {
    align-self: stretch;
    flex-direction: column;
}

.link6 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-3xs);
    color: var(--gray-a5a7b2);
}

.frameLink1 {
    gap: var(--gap-xs);
}

.frameLink,
.frameLink1,
.frameLink2,
.frameLink3,
.link10 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.link10 {
    align-self: stretch;
    flex-direction: row;
    color: var(--gray-a5a7b2);
}

.frameLink,
.frameLink2,
.frameLink3 {
    flex-direction: column;
}

.frameLink2 {
    align-self: stretch;
    height: 46px;
    flex-shrink: 0;
    gap: var(--gap-xs);
}

.frameLink,
.frameLink3 {
    flex: 1;
    color: var(--white-ffffff);
}

.frameLink {
    align-self: stretch;
    gap: var(--gap-2xl);
}

.frameLink3 {
    gap: var(--gap-xs);
}

.icons20px1 {
    position: absolute;
    height: 50%;
    width: 50%;
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.iconButton3 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: var(--gray-52545c);
    position: relative;
    border-radius: var(--br-sm);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    transition: background-color 0.2s;
}

.iconButton3:hover {
    background-color: var(--gray-81838e);
}

.frameLink4 {
    width: 196px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.visaIcon {
    position: relative;
    width: 37.08px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.groupIcon,
.mirLogo1Icon {
    position: relative;
    width: 38.83px;
    height: 24px;
    flex-shrink: 0;
}

.mirLogo1Icon {
    width: 42.39px;
    height: 12px;
    overflow: hidden;
}

.socialNetworks2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-md);
}

.frameLinkParent,
.links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.frameLinkParent {
    flex: 1;
    flex-direction: column;
}

.links {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 var(--padding-xl);
}

.div76,
.footerChild {
    position: relative;
    flex-shrink: 0;
}

.footerChild {
    align-self: stretch;
    background-color: var(--gray-33343a);
    height: 1px;
}

.div76 {
    display: flex;
    align-items: center;
    width: 197px;
}

.links1,
.links2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.links2 {
    justify-content: flex-end;
    gap: var(--gap-md);
}

.links1 {
    align-self: stretch;
    padding: var(--padding-3xs) 0 0;
    justify-content: space-between;
}

.footer,
.footer1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer1 {
    align-self: stretch;
    align-items: flex-start;
}

.footer {
    overflow: hidden;
    text-align: left;
}

.footer {
    align-self: stretch;
    background-color: var(--black-222222);
    padding: var(--padding-4xl) var(--padding-4xl) var(--padding-sm);
    align-items: flex-start;
    font-size: var(--exo-2-14-size);
    color: var(--gray-52545c);
}

@media screen and (max-width: 1023px) {
    .h1 {
        font-size: var(--exo-2-40-size);
    }
    .feedback3 {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .searchBar {
        padding: 0;
    }
    .link {
        display: none;
    }
    .icons24px1 {
        display: flex;
    }
    .header {
        padding-left: var(--padding-md);
        padding-right: var(--padding-md);
        box-sizing: border-box;
    }
    .headingsubtitle {
        padding: var(--padding-lg) 0;
        box-sizing: border-box;
    }
    .button,
    .field,
    .field6 {
        display: none;
    }
    .fieldsearchmobile {
        display: flex;
    }
    .searchFieldbutton {
        padding-left: var(--padding-3xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
    .headingsearchField {
        padding-bottom: var(--padding-4xl);
        box-sizing: border-box;
    }
    .div35, .div44 {
        font-size: var(--exo-2-24-size);
    }
    .div36, .div45, .div48 {
        font-size: var(--exo-2-14-size);
    }
    .div33,
    .stories {
        box-sizing: border-box;
    }
    .h21 {
        flex: unset;
        align-self: stretch;
    }
    .heading1 {
        flex-direction: column;
        padding-right: 0;
        padding-bottom: var(--padding-sm);
        box-sizing: border-box;
        width: 100%;
    }
    .questions1 {
        flex: unset;
        align-self: stretch;
    }
    .questions {
        flex-direction: column;
        box-sizing: border-box;
        padding: var(--padding-lg) var(--padding-3xs);
    }
    .footer,
    .help {
        box-sizing: border-box;
    }
    .feedback {
        box-sizing: border-box;
    }
}

@media screen and (max-width: 768px) {

    .container {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .div1,
    .link {
        display: none;
    }
    .buttonIcon {
        padding-left: var(--padding-7xs);
        padding-right: var(--padding-7xs);
        box-sizing: border-box;
    }
    .icons24px1 {
        display: flex;
    }
    .header {
        padding: var(--padding-6xs) var(--padding-3xs);
        box-sizing: border-box;
    }
    .h1 {
        font-size: var(--exo-2-40-size);
    }
    .headingsubtitle {
        padding: var(--padding-lg) var(--padding-3xs) var(--padding-md);
        box-sizing: border-box;
    }
    .button,
    .field,
    .field6 {
        display: none;
    }
    .fieldsearchmobile {
        display: flex;
    }
    .searchFieldbutton {
        padding-left: var(--padding-3xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
    .headingsearchField {
        padding-bottom: var(--padding-4xl);
        box-sizing: border-box;
    }
    .image {
        width: 112px;
        height: 130px;
    }
    .stories {
        padding: var(--padding-lg) var(--padding-3xs);
        box-sizing: border-box;
    }
    .h2 {
        font-size: var(--exo-2-32-size);
    }
    .heading {
        padding-bottom: var(--padding-sm);
        box-sizing: border-box;
    }
    .div35 {
        font-size: var(--exo-2-24-size);
    }
    .stepcards {
        padding-left: var(--padding-3xs);
        padding-top: var(--padding-3xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
        flex: unset;
        align-self: stretch;
    }
    .stepcards2 {
        padding-left: var(--padding-3xs);
        padding-top: var(--padding-3xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
    .div44 {
        font-size: var(--exo-2-24-size);
    }
    .stepcards3 {
        padding-left: var(--padding-3xs);
        padding-top: var(--padding-3xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
    .stepcardsParent {
        flex: unset;
        align-self: stretch;
    }
    .div47 {
        font-size: var(--exo-2-24-size);
    }
    .div33 {
        padding: 86px var(--padding-3xs) var(--padding-lg) var(--padding-3xs);
        box-sizing: border-box;
    }
    .h21 {
        font-size: var(--exo-2-32-size);
        flex: unset;
        align-self: stretch;
    }
    .heading1 {
        flex-direction: column;
        padding-right: 0;
        padding-bottom: var(--padding-sm);
        box-sizing: border-box;
        width: 100%;
    }
    .div49 {
        font-size: var(--design-18-book-size);
    }
    .question,
    .question1 {
        padding: var(--padding-3xs);
        box-sizing: border-box;
    }
    .questions1 {
        width: 100%;
        flex: unset;
        align-self: stretch;
    }
    .questions {
        flex-direction: column;
        box-sizing: border-box;
    }
    .h22 {
        font-size: var(--exo-2-32-size);
    }

    .help {
        padding: var(--padding-lg) var(--padding-3xs);
        box-sizing: border-box;
    }
    .feedback {
        box-sizing: border-box;
    }
    .frameLink,
    .frameLink3,
    .frameLinkParent,
    .logo {
        flex: unset;
        align-self: stretch;
    }
    .links,
    .links1,
    .links2 {
        flex-direction: column;
    }
    .footer {
        padding-left: var(--padding-3xs);
        padding-top: var(--padding-lg);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 428px) {
    .div1,
    .link {
        display: none;
    }
    .buttonIcon {
        padding-right: var(--padding-7xs);
        box-sizing: border-box;
    }
    .icons24px1 {
        display: flex;
    }
    .header {
        padding-top: var(--padding-6xs);
        padding-right: var(--padding-3xs);
        box-sizing: border-box;
    }
    .headingsubtitle {
        padding-left: var(--padding-3xs);
        padding-top: var(--padding-lg);
        padding-bottom: var(--padding-md);
        box-sizing: border-box;
    }
    .button,
    .field,
    .field6 {
        display: none;
    }
    .fieldsearchmobile {
        display: flex;
    }
    .searchFieldbutton {
        padding-left: var(--padding-3xs);
        box-sizing: border-box;
    }
    .headingsearchField {
        padding-bottom: var(--padding-md);
    }
    .headingsearchField,
    .image,
    .stories {
        box-sizing: border-box;
    }
    .image {
        width: 112px;
        padding-top: 0;
    }
    .stories {
        padding-top: var(--padding-lg);
    }
    .h2 {
        font-size: var(--exo-2-32-size);
    }
    .heading {
        padding-bottom: var(--padding-sm);
        box-sizing: border-box;
    }
    .div35 {
        font-size: var(--exo-2-24-size);
    }
    .stepcards {
        padding-top: var(--padding-3xs);
        box-sizing: border-box;
    }
    .stepCards {
        padding-bottom: var(--padding-3xs);
        box-sizing: border-box;
    }
    .stepcards2 {
        padding-top: var(--padding-3xs);
        box-sizing: border-box;
    }
    .div44 {
        font-size: var(--exo-2-24-size);
    }
    .div33,
    .stepcards3 {
        box-sizing: border-box;
    }
    .stepcards3 {
        padding-top: var(--padding-3xs);
    }
    .div33 {
        padding-left: var(--padding-3xs);
        padding-right: var(--padding-3xs);
    }
    .h21 {
        font-size: var(--exo-2-32-size);
    }
    .heading1 {
        padding-bottom: var(--padding-sm);
        box-sizing: border-box;
    }
    .div49 {
        font-size: var(--design-18-book-size);
    }
    .question,
    .question1 {
        padding-top: var(--padding-3xs);
        box-sizing: border-box;
    }
    .questions1 {
        width: 100%;
    }
    .questions {
        padding-top: var(--padding-lg);
        box-sizing: border-box;
    }
    .h22 {
        font-size: var(--exo-2-32-size);
    }
    .feedback,
    .help {
        box-sizing: border-box;
    }
    .help {
        padding-top: var(--padding-lg);
    }
    .frameLink,
    .frameLink3,
    .frameLinkParent,
    .logo {
        flex: unset;
        align-self: stretch;
    }
    .links {
        padding-bottom: var(--padding-xs);
        box-sizing: border-box;
    }
    .links,
    .links1 {
        flex-direction: column;
    }
    .footer {
        padding-left: var(--padding-3xs);
        box-sizing: border-box;
    }
}

.telegramLink {
    text-decoration: none;
    @media screen and (max-width: 1024px) {
        position: absolute;
        bottom: 25px;
    }
    @media screen and (max-width: 640px) {
        position: relative;
        width: 100%;

    }


}
