@import '../../mixins';

.button {
  cursor: pointer;
  padding: var(--padding-2xs) var(--padding-lg);
  align-self: stretch;
  border-radius: var(--br-md);
  @include flex($justify: center, $align: center);
  gap: var(--gap-sm);
  transition: 0.1s;
  font-weight: 600;
  font-size: var(--exo-2-14-size);
  font-family: var(--exo-2-24-regular);
  color: var(--white-ffffff);

  .icon {
    position: relative;
    flex-shrink: 0;
  }
  &:disabled {
    opacity: .7;
    cursor: not-allowed;
  }
}

.contained {
  background-color: var(--blue-007aff);
  border: 0;
  &:not(:disabled):hover,
  &:active {
    background-color: var(--blue-0068d9);
    border: 0;
  }
}
.outlined {
  border: 1px solid var(--gray-d5d7e2);
  background-color: transparent !important;
  transition: color 0.2s ease 0s;
  &:not(:disabled):hover,
  &:active {
    border: 1px solid var(--gray-a5a7b2);
    background-color: transparent !important;
  }
}
.text {
  color: var(--blue-007aff);
  border: none;
  background-color: transparent;
  &:not(:disabled):hover,
  &:active {
    color: var(--blue-0068d9);
  }
}

.contained.secondary {
  background-color: var(--gray-f3f4fb);
  color: var(--gray-33343a);
  &:not(:disabled):hover,
  &:active {
    background-color: var(--gray-d5d7e2);
  }
}
.outlined.secondary {
  border: 1px solid var(--gray-d5d7e2);
  color: var(--gray-33343a);
  &:not(:disabled):hover,
  &:active {
    border: 1px solid var(--gray-a5a7b2);
  }
}
.text.secondary {
  color: var(--gray-33343a);
  &:not(:disabled):hover,
  &:active {
    color: var(--gray-a5a7b2);
  }
}
.hotels {
  &.contained {
    background-color: var(--purple-6100FF);
    border: 0;
    &:not(:disabled):hover,
    &:active {
      background-color: var(--purple-4701b2);
      border: 0;
    }
  }
  &.outlined {
    border: 1px solid var(--gray-d5d7e2);
    background-color: transparent !important;
    &:not(:disabled):hover,
    &:active {
      border: 1px solid var(--gray-a5a7b2);
      background-color: transparent !important;
    }
  }
  &.text {
    color: var(--purple-6100FF);
    border: none;
    background-color: transparent;
    &:not(:disabled):hover,
    &:active {
      color: var(--purple-4701b2);
    }
  }
}
