.filter {
  position: relative;
  border-radius: var(--br-md);
  background-color: var(--white-ffffff);
  width: 310px;
  height: 98px;
  padding: var(--padding-sm);

  &:not(:last-child) {
    margin-bottom: var(--padding-4xs);
  }

  .nameFilter {
    border-radius: var(--br-sm);
    background-color: var(--gray-f3f4fb);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
