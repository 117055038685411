@import '../../mixins';

.feedback {
    flex: 1;
    @include flex($direction: column);
    gap: var(--gap-md);
    align-self: stretch;
    padding: var(--padding-md);
    border-radius: var(--br-lg);
    background-color: var(--white-ffffff);
    min-width: 400px;

    @media screen and (max-width: 470px) {
        width: calc(100% - 32px);
        min-width: unset;
    }
}

.noPadding {
    padding: var(--padding-sm) 0 0 0 0;
}

.photodata {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    text-align: right;
}

.date {
    flex: 1;
    position: relative;
    font-size: var(--exo-2-14-size);
    color: var(--gray-d5d7e2);
}

.name {
    align-self: stretch;
    position: relative;
    font-size: var(--exo-2-20-size);
    font-weight: 600;
    color: var(--black-000000);
}

.vectorIcon {
    position: relative;
    width: 13px;
    height: 12px;
    flex-shrink: 0;
}

.avatar {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    overflow: hidden;
    object-fit: cover;
    background-color: var(--gray-f3f4fb);
}

.ratingText {
    position: relative;
    font-weight: 600;
    font-size: var(--exo-2-14-size);
}

.photodataParent,
.rating {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.rating {
    border-radius: var(--br-lg);
    background-color: var(--purple-6100FF);
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-7xs);
    align-items: center;
    color: var(--white-ffffff);
}

.photodataParent {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
}

.reviewText {
    position: relative;
    //text-align: justify;
    align-self: stretch;
    flex: 1 1;
    font-size: var(--exo-2-16-size);
    color: var(--black-000000);
    white-space: pre-wrap;
}

.overflow {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: -o-ellipsis-lastline;
}

.arrowFuther {
    color: var(--purple-6100FF);
    align-self: flex-start;
    padding: 0;
    @include flex($align: center);
    gap: var(--gap-5xs);
    cursor: pointer;
    &:not(:disabled):hover,
    &:active {
        color: var(--purple-6100FF);
    }

}

.ratingAnswer {
    @include flex($align: center, $justify: space-between)
}

.answer {
    background-color: var(--gray-eef0f6);
    color: var(--gray-81838e);
    margin-left: 12px;
}

.answerWrapper {
    background-color: var(--gray-f3f4fb);
    padding: 16px;
    border-radius: 16px;
    width: 100%;
}

.answerTop {
    @include flex($align: center, $justify: space-between);
    margin-bottom: 6px
}

.answerText {
    font-weight: 400;
    font-size: var(--exo-2-14-size)
}

.showFullData {
    margin-left: 10px;
    color: var(--gray-a5a7b2);
}

.answerDate {
    font-size: var(--exo-2-14-size);
    color: var(--gray-d5d7e2);
}

.answerHeader {
    @include flex($align: center, $justify: space-between)
}

.vectorIconTrip {
    position: relative;
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    margin-right: 4px;
}

.vectorIconApproved {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 4px;
}

.showFullName {
    font-size: 32px;
}
