.wrapper{
}

.warningBox {
    display: flex;
    background: #FFF4E3;
    border: 1px solid #FFF4E3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    font-size: var(--exo-2-14-size);
    font-weight: 400;
    grid-gap: 3px;

    ul {
        padding-left: 20px;
    }
}

.warningIcon {
    margin-top: 0px;
    width: 20px;
    height: 20px;
}
.wrapperIcon{
    height: 100%;
}
