@import '../../../../mixins';

.pagination {
  margin: 0;
}

.root{
  padding-top: 16px;
  .links_mobile{
    display: none;
  }

  .links_mobile_link {
    .link_name {
      font-weight: bolder;
    }
  }

  .link_passengers_row {
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
      justify-content: center;
    }

    img {
      margin-right: 5px;
    }
  }

  .link_name{
    display: flex;
    align-items: center;

    p{
      margin: 0;
    }

    &_link{
      margin-left: 8px;

      &_name{
        font-size: var(--exo-2-14-size);
        font-weight: 600;
        margin-bottom: 4px;
      }

      &_value{
        color: var(--blue-007aff);

        @media screen and (max-width: 480px) {
          max-width: 66vw;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .sort_arrow{
    margin-left: 4px;
  }

  @media screen and (max-width: 768px) {
    table{
      display: none;
    }

    .links_mobile{
      display: flex;
      font-size: var(--exo-2-14-size);
      flex-direction: column;

      &_button{
        background: var(--white-ffffff);
        border: 1px solid var(--blue-007aff);
        color: var(--blue-007aff);

        &:hover{
          background: var(--white-ffffff);
          border: 1px solid var(--blue-0068d9);
          color: var(--blue-0068d9);
        }
      }

      &_link{
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--gray-eef0f6);

        &_info{
          display: flex;
          flex-wrap: wrap;

          &_item{
            width: 50%;

            .label{
              color: var(--gray-81838e);
              margin-bottom: 4px;
            }

            .value{
              font-weight: 600;
            }
          }
        }
      }

      &_name{

        font-weight: 600;
      }
    }
  }

}


.sort_window{
  display: none;
  padding: 16px;

  &_opened{
    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      bottom: 0;
      background: #fff;
      left: 0;
      z-index: 11;

      .sort_types{
        &_type{
          font-size: var(--exo-2-14-size);
          color: var(--gray-81838e);
          margin-bottom: 20px;
          cursor: pointer;
        }
        &_order{
          color: var(--blue-007aff);
        }
      }
    }
  }

  &_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    margin-bottom: 32px;
  }


  img{
    margin-left: 4px;
  }

  &_button{
    width: calc(100% - 32px);
    position: absolute;
    bottom: 16px;
  }
}

