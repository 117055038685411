@import '../../../mixins';

.container {
  position: relative;

  .list {
    flex: 1;
    background-color: var(--white-ffffff);
    @include flex($direction: column, $align: center);
    gap: var(--gap-md);
    padding-top: 10px;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      background-color: var(--gray-eef0f6);
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-a5a7b2);
      border-radius: 3px;
    }

    &_scrollable{
      overflow-y: auto;
      max-height: 285px;

      @media screen and (max-width: 1024px) {
        & {
          overflow-y: scroll;
          max-height: 200px;
        }
      }
    }
  }

  .shade {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: -12px;
    left: 0;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
    height: 40px;
  }
}

.icon {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  object-fit: contain;
}