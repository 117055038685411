@import '../../mixins';

.root {
  @include flex($direction: column, $align: stretch);
  align-self: stretch;
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  box-shadow: var(--card);
  padding: var(--padding-sm);
  gap: var(--gap-md);

  @media screen and (max-width: 768px) {
    padding: var(--padding-3xs);
  }
}
