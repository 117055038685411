@import '../../../mixins';

.navLight,
.navDark,
.link {
  @include flex($justify: center, $align: center);
}

.navLight,
.navDark {
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--exo-2-16-size);
  color: var(--white-ffffff);
    height: 100%;

  .link {
    a {
      font-weight: 500;
      color: inherit;
      display: inline-block;
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        color: var(--blue-007aff);
      }
    }
  }
}

.navLight {
  color: var(--black-000000);
}

@media screen and (max-width: 1024px) {
  .link {
    display: none;
  }
}
