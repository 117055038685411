@import '../../../mixins';

.paper {
  min-width: 470px;
  //max-width: unset !important;
  border-radius: var(--br-xl) !important;
  overflow: hidden;  
}
.root {
  @include flex($direction: column);
  padding: 0 var(--padding-lg) var(--padding-lg);
  background-color: var(--white-ffffff);
  font-weight: 300;
  overflow-x: hidden;
  overflow-y: auto;
}
.header {
  padding: 0 0 0 var(--padding-lg);
  //overflow-x: hidden;
  //overflow-y: auto;
}
.h1 {
  font-size: var(--exo-2-28-size);
  color: var(--black-000000);
  font-weight: 600;
  margin: 0px;
}
.close {
  @include flex($justify: flex-end, $align: center);
  align-self: stretch;
  margin: var(--padding-sm) var(--padding-sm) 0;
}
.cross {
  padding: 0;
  filter: saturate(0) brightness(1.8);
  transition: 0.3s;
  img {
    width: 24px;
  }
  &:hover {
    filter: saturate(0) brightness(1.4);
  }
}

@media screen and (max-width: 768px) {
  .paper {
    min-width: 95vw;
    //margin: 0 !important;
  }
  .root {
    padding: 0 var(--padding-sm) var(--padding-sm);
  }
  .header {
    padding: 0 var(--padding-sm) 10px var(--padding-sm);
  }
  .h1 {
    font-size: var(--exo-2-24-size);
  }
}
