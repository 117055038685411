.gradientHome,
.headerhome1Icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
.headerhome1Icon {
  height: 100%;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.gradientHome {
  margin: 0 !important;
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  background-color: var(--black-222222);
  height: 160px;
  flex-shrink: 0;
  padding-top: var(--padding-xs);
  z-index: 0;
}
.headerCatalogTickets,
.home,
.searchDropdown {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.searchDropdown {
  padding: var(--padding-sm) 0 0 0;
  box-sizing: border-box;
  max-width: var(--max-width);
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .searchDropdown{
    padding: var(--padding-sm) 0 0 0 !important;
  }
}

@media screen and (max-width: 1440px) {
  .searchDropdown{
    padding: var(--padding-sm) var(--padding-md) 0 var(--padding-md);
  }
}



.headerCatalogTickets,
.home {
  gap: var(--gap-0);
}
.headerCatalogTickets {
  position: relative;
  text-align: left;
  font-size: var(--exo-2-16-size);
  color: var(--black-000000);
  font-family: var(--exo-2-24-regular);
  background-color: var(--gray-f3f4fb);
}
@media screen and (max-width: 1024px) {
  .searchDropdown {
    padding: var(--padding-3xl) var(--padding-3xs) 0 var(--padding-3xs);
  }
}
@media screen and (max-width: 768px) {
  .gradientHome {
    height: 116px;
    padding-top: var(--padding-lg);
  }
}
