@import '../../../../mixins';

.root {
  @include flex($direction: column);
  gap: var(--gap-sm);
  padding: var(--padding-sm);
  font-size: var(--exo-2-14-size);
  font-weight: 600;
  color: var(--black-000000);

  .column {
    @include flex($direction: column);
  }

  .row {
    @include flex($direction: row, $align: center);
    gap: var(--gap-xs);
  }

  .text {
    color: #A5A7B2;
    font-size: var(--exo-2-14-size);
    font-weight: normal;
  }

  .main {
    margin: var(--padding-8xs) 0;
    min-width: 300px;

    @media (max-width: 1430px) {
      min-width: 60px;
    }

    @media (max-width: 768px) {
      min-width: 300px;
    }
  }

  .text {
    margin: var(--padding-8xs) 0;
    min-width: 300px;

    @media (max-width: 1430px) {
      min-width: 60px;
    }

    @media (max-width: 768px) {
      min-width: 300px;
    }

  }

  .value {
    font-size: var(--exo-2-24-size);
    color: var(--orange-ffa620);
  }
}
