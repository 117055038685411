@import '../../../mixins';

.checkbox {
  align-self: stretch;
  @include flex($align: center);
  gap: var(--gap-xs);
  text-align: left;
  border-radius: var(--br-2xl);
  color: var(--gray-52545c);
  font-size: var(--exo-2-14-size);

  .input {
    width: 20px;
    height: 20px;
    border-radius: var(--br-sm);
    background-color: var(--white-ffffff);
    border: 1px solid var(--gray-a5a7b2);

    padding: 0;
    margin: 0;
    appearance: none;
    position: relative;
  }

  .input:active {
    border: 1px solid var(--gray-52545c);
  }

  .input:checked {
    .input::after {
      content: '../icons/tick.svg';
      position: absolute;
      top: 0;
      left: 0;
    }

  }
  .label {
    @include flex($align: center);
    gap: var(--gap-xs);
  }
}
