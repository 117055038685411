@import '../../mixins';

.input {
  align-self: stretch;
  flex: 1;
}

.readOnly {
  background-color: var(--gray-f3f4fb) !important;
  color: var(--gray-a5a7b2) !important;
}

.wrapper {
  @include flex($direction: column);
  flex: 1;
  align-self: stretch;
  gap: var(--gap-2xs);
}
.InputAdornment {
  margin-top: 0 !important;
  height: 100%;
  @include flex($justify: center, $align: center);
}
.passswordButton {
  background-color: transparent;
  transition: 0.3s;
  &:hover {
    background-color: transparent;
    filter: brightness(0.7);
  }
}

input:disabled{
  background-color: var(--gray-f3f4fb);
}
