@import '../../mixins';

.footer,
.content,
.links,
.logo,
.frameLink,
.footerSection,
.footerSectionItems,
.frameSocial,
.frameSocialItem,
.socialNetworks,
.copyright,
.oferta {
    @include flex($direction: column);
    align-self: stretch;
}

.footer {
    background-color: var(--black-222222);
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-5xl) var(--padding-5xl) var(--padding-lg);
    text-align: left;
    font-size: var(--exo-2-14-size);
    color: var(--gray-52545c);
    font-family: var(--exo-2-24-regular);

    .content {
        flex: 1;
        width: 100%;
        align-self: stretch;
        max-width: var(--max-width);
        gap: var(--gap-xl);
    }

    .links {
        flex-direction: row;
        padding: 0 0 var(--padding-3xl);
        gap: var(--gap-xl);
        border-bottom: 1px solid var(--gray-33343a);

        .logo {
            flex: 1 1;
            @include flex($direction: column);
            gap: var(--gap-sm);
        }

        .frameLink {
            flex-wrap: wrap;
            flex: 2 1;
            gap: var(--gap-2xl);
            color: var(--white-ffffff);
            max-height: 220px;
            flex-direction: row;

            .footerSection {
                flex-direction: column;
                gap: var(--gap-sm);
                flex: 1 1;

                .footerSectionHeader {
                    font-weight: 600;
                }

                .footerSectionItems {
                    flex-direction: column;
                    justify-content: center;
                    gap: var(--gap-xs);

                    .footerSectionItem {
                        color: var(--gray-a5a7b2);
                        transition: color 0.2s;

                        &:hover {
                            color: var(--gray-eef0f6);
                        }

                        a {
                            color: inherit;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .frameSocial {
            flex: 1 1;
            gap: var(--gap-xl);

            .frameSocialItem {
                gap: var(--gap-sm);
            }

            .socialNetworks {
                gap: var(--gap-xs);
                flex-direction: row;
                align-items: center;

                .telegramButton {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .linkDelaemIt {
        color: inherit;
        text-decoration: none;
    }

    .copyright {
        text-decoration: none;
        flex-direction: row;
        justify-content: space-between;
        padding-top: var(--gap-sm);

        .visaIcon,
        .mirIcon,
        .mcIcon {
            position: relative;
            width: 37.08px;
            height: 12px;
            flex-shrink: 0;
            overflow: hidden;
        }

        .oferta {
            flex-direction: row;
            gap: var(--gap-xl);

            a {
                text-decoration: none;
                color: var(--gray-52545c);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer {
        padding-left: var(--padding-xs);
        padding-top: var(--padding-2xl);
        padding-right: var(--padding-xs);
        flex-direction: column;

        .links {
            flex-direction: column;

            .frameDiv,
            .frameLink,
            .logo {
                flex: unset;
                align-self: stretch;
                max-height: unset;
            }

            .frameLink {
                flex-direction: column;
            }
        }

        .copyright,
        .copyright .oferta {
            flex-direction: column;
        }

        .copyright .oferta {
            margin-top: var(--padding-lg);
            gap: var(--gap-sm);
        }
    }
}

@media screen and (max-width: 428px) {
    .footer {
        padding-left: var(--padding-xs);

        .links {
            padding-bottom: var(--padding-md);
            flex-direction: column;

            .frameSocial,
            .frameLink,
            .frameLink3,
            .logo {
                flex: unset;
                align-self: stretch;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .footer {
        padding-left: var(--padding-xs);
        padding-right: var(--padding-xs);
    }
}
