@import '../../../mixins';

.wrapper {
  align-self: stretch;
  @include flex($direction: column, $justify: center);
  flex: 1 1;
}

.field {
  width: 100%;
  cursor: pointer;
  border: 0;
  padding: var(--padding-8xs) var(--padding-3xs);
  background-color: var(--white-ffffff);
  flex: 1;
  border-radius: var(--br-md);
  align-self: stretch;
  @include flex($justify: center, $align: center);
  gap: var(--gap-xs);
  position: relative;
  border: 1px solid var(--gray-d5d7e2);
  border-radius: var(--br-sm);
  min-height: 56px;
  max-height: 56px;

  .placeholder {
    position: relative;
    font-size: var(--exo-2-12-size);
    font-family: var(--exo-2-24-regular);
    color: var(--gray-a5a7b2);
    text-align: left;
    display: inline-block;
    text-transform: none;
    transition: 0.3s;
  }
  .placeholder.novalue {
    font-size: var(--exo-2-16-size);
  }
  .value,
  .subtitleheading {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .value {
    position: relative;
    font-size: var(--exo-2-16-size);
    font-weight: 500;
    color: var(--black-000000);
    text-align: left;
    display: inline-block;
    font-family: var(--exo-2-24-regular);
  }
  .subtitleheading {
    @include flex($direction: column, $justify: center);
    flex: 1;
    gap: var(--gap-5xs);
    line-height: 1.2;
  }
  .iconsArrow {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}

.textField {
  flex: 1 1;

  .inputRoot {
    border: none;
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
  & > div {
    border: 1px solid transparent;
  }
}
.popper {
  z-index: 10;
}

.listbox {
  margin: 0 !important;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: var(--br-sm);
  background-color: var(--white-ffffff);
  box-shadow: var(--box-shadow-main);
  width: 340px;
  max-height: 360px;
  overflow-x: hidden;
  @include flex($direction: column, $justify: center, $align: center);
  gap: var(--gap-0);
  z-index: 10;
}
.withScroll{
  overflow-y: scroll;
}

.disableScroll {
  width: unset;
  max-height: unset;
  overflow-y: hidden;
}

.PassengersSelect {
  position: relative;
  flex: 1;
}
.error {
  border: 1px solid var(--red-ff3d00);
}

.errorText {
  margin-top: 3px;
  line-height: 1.66;
  font-size: var(--exo-2-12-size);
  color: var(--red-ff3d00);
}

@media screen and (max-width: 768px) {
  .DatePicker {
    padding: var(--padding-3xs) 0;
    & > * {
      padding: var(--padding-6xs) 0;
    }
  }
  .listbox {
    width: 330px;
  }
}
