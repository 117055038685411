.container {
    display: flex;
    @media screen and (max-width: 768px){
        padding: 0 15px;

    }


}

@mixin button-navigate($background-button, $color-button) {
    height: 44px;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    border-radius: 8px;
    margin: 10px 10px 10px 0;
    text-align: center;
    background: $background-button;
    color: $color-button;
    &:not(:disabled):hover,
  &:active{
        background: $background-button;
        color: $color-button;
        border-color: inherit;
    }

}


.aviaButton {
    @include button-navigate(var(--black-000000),var(--white-ffffff));
}

.hotelsButton {
    @include button-navigate(var(--black-000000),var(--white-ffffff));
}

.hotelsButton.active{
    @include button-navigate(var(--white-ffffff),var(--black-000000));
}

.aviaButton.active {
    @include button-navigate(var(--white-ffffff),var(--black-000000));
}

@mixin button-img() {
    width: 20px;
    height: 20px;
}

.imgHotels {
    @include button-img
}

.imgAvia {
    @include button-img

}
