@import "../../mixins";

.root {
    position: sticky;
    top: var(--padding-3xs);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: var(--br-xl);
    background-color: var(--white-ffffff);
    box-shadow: var(--header);
    width: 310px;
    flex-shrink: 0;
    padding: var(--padding-sm);
    box-sizing: border-box;
    gap: var(--gap-md);
    font-size: var(--exo-2-14-size);
    color: var(--gray-a5a7b2);
    float: right;

    @media screen and (max-width: 1024px) {
        width: 100%;
        margin-top: var(--padding-4xs);
    }
}

.textarrow {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
    color: var(--black-000000);
    filter: grayscale(100%);
    padding: 0;
    outline: none;
}

.invert {
    img {
        transform: rotateX(180deg);
    }
}

.guests {
    width: 100%;
    color: var(--black-000000);
}

.containerGuests {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: var(--exo-2-14-size);
    font-weight: 600;
}

.errorText {
    margin-top: 3px;
    line-height: 1.66;
    font-size: var(--exo-2-12-size);
    color: var(--red-ff3d00);
}

.basket {
    @include flex($direction: column);
    align-self: stretch;
    gap: var(--gap-3xs);
    font-size: var(--exo-2-12-size);

    .basketHeading {
        align-self: stretch;
        @include flex();
        font-size: var(--exo-2-14-size);
        color: var(--black-000000);
    }
}

.totalPrice, .totalPriceEur {
    flex: 1;
    font-size: var(--exo-2-14-size);
    font-weight: 600;
    color: var(--black-000000);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalPriceEur {
    margin-top: 5px;
}

.totalPrice span {
    font-size: var(--exo-2-24-size);
}

.totalPriceEur span {
    font-size: var(--exo-2-16-size);
}

.summary {
    color: var(--gray-a5a7b2);
    border-top: 1px solid var(--gray-eef0f6);
    width: 100%;
}
.detailSumm{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: var(--gray-a5a7b2);
    font-size:var(--exo-2-12-size);
}

.iconWarning {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.warning,
.order {
    align-self: stretch;
    @include flex();
}

.warning {
    position: relative;
    align-items: center;
    gap: var(--gap-5xs);
    color: var(--orange-ff7a00);
    cursor: pointer;

    .warningTooltip {
        display: none;
        padding: 5px;
        position: absolute;
        top: calc(-100% - 40px);
        left: 0;
        background: rgba(0, 0, 0, .5);
        border-radius: 6px;
        color: #fff;
        font-size: 12px;
    }

    &:hover {
        .warningTooltip {
            display: block;
        }
    }
}

.order {
    flex-direction: column;
    gap: var(--gap-xs);
}

.agreementText {
    font-weight: 300;
    font-size: var(--exo-2-14-size);

    .link {
        text-decoration: underline;
        color: inherit;

        &:hover {
            color: var(--blue-007aff);
        }
    }
}

.checkbox {
    align-items: flex-start;
}

.button {
    @include flex($direction: column);
    cursor: pointer;
    align-self: stretch;
    gap: var(--gap-3xs);
    font-size: var(--exo-2-12-size);
    background: #6100FF;
    border-radius: 8px;
    align-items: center;


}

