@import '../../../mixins';

.root {
  width: 100%;
}

.content {
  @include flex($direction: column);
  gap: var(--gap-sm);
}

.h1 {
  display: block;
  font-size: var(--exo-2-32-size);
  margin: 0;
  font-weight: 500;
}

.description {
  display: block;
  font-size: var(--exo-2-14-size);
  color: var(--gray-a5a7b2);
  margin: 10px 0;
}

.card {
  width: 100%;
}

.header,
.total,
.col,
.row {
  width: 100%;
  @include flex($justify: space-between);
  gap: var(--gap-xs);
}

.col {
  @include flex($direction: column);
  width: min-content;
}

.header {
  margin: var(--padding-sm) 0;
  justify-content: space-between;
  align-items: center;
}

.passengers {
  img {
    filter: brightness(3.4);
    transform: scale(1.3);
  }
}
.income {
  min-width: 371px;
  display: flex;
  flex-direction: row!important;
  justify-content: space-between!important;
  & > span {
    color: var(--green-60b56e);
  }
}

.more {
  align-self: flex-end;
  padding: 5px;
  color: var(--blue-007aff)!important;
}

.wrapper {
  position: relative;
  @include flex($direction: column);
  align-self: flex-end;
  gap: var(--gap-2xs);


  @media screen and (max-width: 800px) {
    align-self: flex-start;
  }
}
.select {
  padding: 0 var(--padding-xs);
  border: none;
}

@media screen and (max-width: 800px) {
  .header,
  .row {
    flex-direction: column;
  }

  .sortButton {
    align-self: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .total{
    overflow-x: scroll;
  }
}