@import '../../../../mixins';
.root {
  @include flex($direction: column, $justify: center);
  align-self: stretch;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;

  .heading {
    font-size: var(--exo-2-20-size);
    color: var(--black-000000);
    margin-bottom: var(--padding-xs);
    font-weight: 600;
  }
}
.tabs {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-gap: var(--gap-md);
  align-self: stretch;

  .input {
    align-self: stretch;
    flex: 1;

    fieldset{
      border: none;
    }
  }

  .select {
    // border: 1px solid var(--gray-d5d7e2);
    // border-radius: var(--br-sm);
    padding: 0 var(--padding-4xs);
  }
  // .error {
  //   border: 1px solid var(--red-ff3d00);
  // }
  .selectWrapper {
    flex: 1;
  }

  .wrapper {
    position: relative;
    @include flex($direction: column);
    flex: 1;
    align-self: flex-start;
    gap: var(--gap-2xs);
  }
}

.tabs50 {
  display: grid;
  grid-template-columns: repeat(2, 48.6%);
  grid-gap: var(--gap-md);
  align-self: stretch;

  .input {
    align-self: stretch;
    flex: 1;

    fieldset{
      border: none;
    }
  }

  .select {    
    padding: 0 var(--padding-4xs);
  }  
  .selectWrapper {
    flex: 1;
  }

  .wrapper {
    position: relative;
    @include flex($direction: column);
    flex: 1;
    align-self: flex-start;
    gap: var(--gap-2xs);
  }
}

@media screen and (max-width: 768px) {
  .tabs {
    grid-template-columns: 1fr;

    .select {
      padding: 0 var(--padding-6xs);
      min-height: 56px;
    }
  }
}

@media screen and (max-width: 420px) {  
  .tabs50 {
    grid-template-columns: repeat(1, 99.6%);
  }  
}
