@import '../../../mixins';

.group {
  @include flex($direction: column, $justify: center);
  gap: var(--gap-sm);
  font-size: var(--exo-2-14-regular1-size);

  .direction {
    color: var(--gray-a5a7b2);
  }

  .filterGroup {
    align-self: stretch;
    @include flex($direction: column, $align: space-between);
    gap: var(--gap-0);

    .labelsGroup {
      @include flex($direction: column, $justify: center);
      gap: var(--gap-2xs);

      .labels {
        align-self: stretch;
        @include flex($justify: space-between);
      }
    }
  }
}
