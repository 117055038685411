@import '../../../mixins';

.root {
  cursor: pointer;
  border: 0;
  padding: var(--padding-2xs) var(--padding-xs);
  background-color: var(--white-ffffff);
  align-self: stretch;
  @include flex($justify: center, $align: center);
  gap: var(--gap-xs);
  transition: background-color 0.2s;

  .icon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .value {
    align-self: stretch;
    position: relative;
    font-size: var(--exo-2-14-size);
    font-family: var(--exo-2-24-regular);
    color: var(--black-000000);
    text-align: left;
    display: inline-block;
  }
  .title {
    flex: 1;
    @include flex($direction: column, $justify: center);
    gap: var(--gap-3xs);
  }
  .code {
    position: relative;
    font-size: var(--exo-2-14-size);
    font-family: var(--exo-2-24-regular);
    color: var(--gray-a5a7b2);
    text-align: left;
    display: inline-block;
  }
}
.root:hover {
  background-color: var(--gray-f3f4fb);
}
