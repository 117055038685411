@import '../../../../mixins';

.personalInfo {
  @include flex($direction: column);
  align-self: stretch;
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  padding: var(--padding-md);
  gap: var(--gap-2xl);
}
.description {
  align-self: stretch;
  position: relative;
  font-size: var(--exo-2-14-size);
  color: var(--gray-81838e);
}
.headingsubtitle3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div30 {
  align-self: stretch;
  position: relative;
  font-size: var(--exo-2-16-size);
  font-weight: 500;
  color: var(--black-000000);
}
.subtitleheading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-6xs);
}

.wrapper {
  @include flex($direction: column);
  flex: 1;
  align-self: stretch;
  gap: var(--gap-2xs);
}

.contacts {
  @include flex();
  gap: var(--gap-xs);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-md);
  }
}
.switchheading {
  align-self: stretch;
  @include flex();
  flex-direction: row;
  padding: 0 0 var(--padding-4xs);
  align-items: center;
  gap: var(--gap-3xs);
}
.headingsubtitle4 {
  @include flex($direction: column);
  border-radius: var(--br-sm);
  background-color: var(--gray-f3f4fb);
  padding: var(--padding-3xs);
  font-size: var(--exo-2-16-size);
}
.root {
  @include flex($direction: column, $align: stretch);
  gap: var(--gap-lg);
  border-radius: var(--br-lg);
  background-color: var(--white-ffffff);
  padding: var(--padding-md);
}
.header {
  font-size: var(--exo-2-20-size);
  margin-bottom: var(--padding-6xs);
  font-weight: 600;
}
