@import '../../mixins';

.root {
  @include flex($justify: center, $align: center);
  gap: var(--gap-xl);
  padding: 0 var(--padding-2xl);
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 65px;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);

  .container {
    max-width: var(--max-width);
    @include flex($justify: space-between, $align: center);
    gap: var(--gap-xs);
    width: 100%;
  }

  .text {
    flex: 1;
    color: var(--white-ffffff);
    font-size: var(--exo-2-14-size);
  }
  .button {
    padding: var(--padding-4xs) var(--padding-2xl);
    align-self: center;
  }
}

@media screen and (max-width: 768px) {
  .root {
    height: unset;
    gap: var(--gap-xs);
    padding: var(--padding-xs) var(--padding-2xl);

    .container {
      flex-direction: column;
    }

    .text {
      text-align: center;
    }
  }
}
