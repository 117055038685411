@import '../../../mixins';

.root {
  @include flex($align: center);
  position: relative;
  gap: var(--gap-md);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar {
    background: transparent;
    opacity: 0;
  }
}

.icons16px {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
}
.scrollArrow {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-gray-1500);
  position: relative;
  border-radius: var(--br-3xl);
  backdrop-filter: blur(4px);
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  overflow: hidden;
  transition: background-color 0.2s;
}
.scrollArrow:hover {
  background-color: var(--color-gray-1400);
}
.image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.storiesIndexBar {
  flex: 1;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--white-ffffff);
  height: 2px;
  overflow: hidden;
  opacity: 0.2;
}
.storiesIndexBarContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: var(--blue-007aff);
}
.active {
  opacity: 1;
}
.stageStories {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.icons24pxChild {
  position: absolute;
  height: 83.33%;
  width: 83.33%;
  top: 8.33%;
  right: 8.33%;
  bottom: 8.33%;
  left: 8.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.close {
  @include flex($justify: flex-end, $align: center);
  align-self: flex-end;
  padding: 0;
  width: 24px;
  height: 24px;
  img {
    filter: grayscale(1) brightness(3);
  }
}
.stageClosed,
.storiesTextBG {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.stageClosed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--padding-3xs);
  align-items: flex-end;
  gap: var(--gap-md);
}
.storiesTextBG {
  background: linear-gradient(180deg, #262626, rgba(43, 43, 43, 0));
  height: 357px;
  flex-shrink: 0;
  padding: var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-start;
}
.title {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.subtitle {
  font-size: var(--exo-2-16-size);
}
.div {
  position: relative;
  font-size: var(--exo-2-14-size);
  font-weight: 600;
  font-family: var(--exo-2-14-semibold2);
  color: var(--white-ffffff) !important;
  text-align: center;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-md);
  margin: var(--padding-3xs);
  background-color: var(--blue-007aff);
  align-self: stretch;
  border-radius: var(--br-lg);
  /*display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;*/
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  transition: background-color 0.1s;
  text-decoration: none;
  z-index: 1;
}
.slideSwithers,
.contenbutton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.slideSwithersWrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  height: 658px;
  flex: 1;
}
.prev,
.next {
  flex: 1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.storiesContent {
  flex: 1;
  position: relative;
  border-radius: var(--br-3xl);
  background-color: var(--gray-f3f4fb);
  height: 658px;
  overflow: hidden;
}
.stories {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 423px;
  flex-shrink: 0;
}
.storiesText {
  text-align: center;
  margin-top: 1.125rem;
  color: var(--white-ffffff) !important;
}

@media screen and (max-width: 1024px) {
  .scrollArrow {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .stories {
    width: 80vw;
  }
  .storiesContent {
    height: 80vh;
  }
}
@media screen and (max-width: 500px) {
  .stories {
    width: 100vw;
  }
  .storiesContent {
    height: 100vh;
  }
}
