@import '../../mixins';

.buttonHeader,
.headerLight,
.headerDark {
  @include flex($justify: center, $align: center);
}
.buttonHeader {
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--exo-2-14-size);
}
.headerLight,
.headerDark {
  align-self: stretch;
  padding: var(--padding-xs) var(--padding-md);
  text-align: left;
  color: var(--white-ffffff);
  font-size: var(--exo-2-16-size);
  font-family: var(--exo-2-24-regular);
  top: 0;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    padding: var(--padding-xs) var(--padding-3xs);
  }

  .iconBurger {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    transition: 0.3s;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
.headerLight {
  background-color: var(--white-ffffff);
  color: var(--black-000000);
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  box-shadow: var(--header);
  position: sticky;

  .iconBurger {
    filter: brightness(0);
  }
}

.headerContent {
  width: 100%;
  align-self: stretch;
  @include flex($justify: space-between, $align: center);
  max-width: var(--max-width);
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .headerLight,
  .headerDark {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);

    .iconBurger {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .headerLight,
  .headerDark {
    padding: var(--padding-3xs);
  }
}
