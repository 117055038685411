@import '../../../../mixins';

.root {
  @include flex($direction: column);
  gap: var(--gap-sm);
  padding: var(--padding-sm);
  font-size: var(--exo-2-14-size);
  font-weight: 600;
  color: var(--black-000000);

  .row {
    @include flex($align: center);
    gap: var(--gap-sm);
  }

  .main {
    margin: var(--padding-8xs) 0;
  }
  .value {
    font-size: var(--exo-2-24-size);
  }
}
